// nesting is bad because we need to use deep clone
// id is generated with node-uuid, it can be anything
// if a resource is available, it has the `isEnabledByProductsStatus and isEnabledByRole`
// if resources name has !, it is a required field

/* Resource Fields (Keys) Documentation:
 {
    id: uuid, used in the url to open resource
    pageTitle: 'Resource',
    imgSrc: image source,
    resourceType: video -> display play icon, externalLink -> opens new tab
    title!: Title of resource tile,
    titleInHeader!: Flag to show or hide the title on the header in resource template
    shortDescription: text displayed in resource tile,
    productCategory: tags,
    isS3Path: Flag to identify the src as an S3 path instead of a direct link.
    src: url of resource,
    roles: controls access along with isEnabledByRole, should look to merge the 2
    uploadDate: null, the date on which this resource is uploaded in format YYYY-MM-DD
    isEnabledByRole: ({ userRoles }) => condition to show resource depending on role,
    isEnabledByProductsStatus: ({ ...userParams }) => condition to show resource depending on what products status user has,
    tag: tag of resource for going back (eg. provider-toolkit)
  }
*/

import ROLES from '../../utils/constants/roles'
import { includesSome } from '../includes'
import FILES from '../constants/files'

// TODO: maybe move these into separate files.
const GENERAL_RESOURCES = [
  {
    // for providers
    id: '6dff3f06-f444-4201-ae59-3888e8ef9eaf',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/1.png',
    resourceType: 'videos',
    title: 'Intro Video',
    titleInHeader: true,
    shortDescription: 'Welcome! Watch this introductory video to get started with SSP!',
    productCategory: ['SSP', 'Video', 'Getting Started'],
    src: FILES.introVideo.sspProvider,
    roles: ['provider_all_clients', 'provider'],
    uploadDate: null,
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasSspProducts }) => hasSspProducts,
  },
  {
    // for clients
    id: '18630964-6bd3-425f-96d6-e51bc3c992b1',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/1.png',
    resourceType: 'videos',
    title: 'SSP Client Intro Video',
    titleInHeader: true,
    shortDescription: 'Welcome! Watch this introductory video to get started with SSP!',
    productCategory: ['SSP', 'Video', 'Getting Started'],
    src: FILES.introVideo.sspClient,
    roles: ['client'],
    uploadDate: null,
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.CLIENT_ROLES),
    isEnabledByProductsStatus: ({ hasSspProducts }) => hasSspProducts,
  },
  {
    id: 'a964775e-67f7-4fdc-ace8-ce3c506c0fd7',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/118.png',
    resourceType: 'videos',
    title: 'Create and Manage Clients',
    titleInHeader: true,
    shortDescription: 'Learn how to create and manage your Client accounts.',
    productCategory: ['SSP', 'Video', 'Getting Started'],
    src: '//player.vimeo.com/video/411182372',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2022-04-11',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasCompletedSspCertification, hasSspProducts }) =>
      hasCompletedSspCertification && hasSspProducts,
  },
  {
    id: '9eead2e6-8493-4e3e-be97-ad471fc8f39d',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/3.png',
    resourceType: 'videos',
    title: "Track your Clients' progress",
    titleInHeader: true,
    shortDescription: "Learn how to track your Clients' progress.",
    productCategory: ['SSP', 'Video', 'Getting Started'],
    src: '//player.vimeo.com/video/411182639',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: null,
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasCompletedSspCertification, hasSspProducts }) =>
      hasCompletedSspCertification && hasSspProducts,
  },
  {
    id: '2f2ecbc3-3b06-4a81-95e6-0be5bc2bdb07',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/4.png',
    resourceType: 'videos',
    title: 'Enable Client Remote Delivery',
    titleInHeader: true,
    shortDescription: 'Learn how to enable remote delivery for your Client.',
    productCategory: ['SSP', 'Video', 'Getting Started'],
    src: '//player.vimeo.com/video/411182782',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: null,
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasCompletedSspCertification, hasSspProducts }) =>
      hasCompletedSspCertification && hasSspProducts,
  },
  {
    id: 'f7f03693-29a6-4171-8293-ddedfe7f3a7e',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/5.png',
    resourceType: 'videos',
    title: 'Using the Unyte Health App',
    titleInHeader: true,
    shortDescription: 'Learn how to navigate and use the Unyte Health app to deliver SSP.',
    productCategory: ['SSP', 'Video', 'Getting Started'],
    src: '//player.vimeo.com/video/490063308',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: null,
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasCompletedSspCertification, hasSspProducts }) =>
      hasCompletedSspCertification && hasSspProducts,
  },
  {
    id: 'd2fc88b5-02e8-4057-97ae-2f5685aed3a2',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/41.png',
    resourceType: 'secure-storage-file',
    title: 'ILS Programs Graph',
    titleInHeader: true,
    shortDescription:
      'A visual aid for understanding hours and filtration (Hz) in each ILS Program.',
    productCategory: ['ILS', 'File', 'Getting Started'],
    src: 'focus/ILS_Programs_Graph.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2022-09-13',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasFocusCertification }) => hasFocusCertification,
  },
  {
    id: '95c0b753-0e3e-472b-a94a-b95021085bcb',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/47.png',
    resourceType: 'secure-storage-file',
    title: 'Foundational ILS Training Manual',
    titleInHeader: true,
    shortDescription:
      'Learn more about the ILS with this Foundational Training Manual for Providers.',
    productCategory: ['ILS', 'Manual', 'Getting Started'],
    src: 'focus/ILS_Training_Manual.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2022-12-07',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasFocusCertification }) => hasFocusCertification,
  },
  {
    id: 'd44b2582-cafd-48ee-9f2a-2dbeeb4538e3',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/63.png',
    resourceType: 'secure-storage-file',
    title: 'VoicePro Training Manual',
    titleInHeader: true,
    shortDescription: 'Learn more about the VoicePro with this Training Manual for Providers.',
    productCategory: ['VoicePro', 'Getting Started', 'Manual'],
    src: 'VoicePro/VoicePro+Training+Manual+10.19.21.docx.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: null,
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasVoiceProCertification }) => hasVoiceProCertification,
  },
  {
    id: 'fc475c61-6ce2-4b87-9005-a68f0970336e',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/8.png',
    resourceType: 'link',
    title: 'Unyte Health Event/Training Calendar',
    titleInHeader: true,
    shortDescription: 'Click here to view our upcoming events and Q&As.',
    productCategory: ['Link', 'Q&A', 'Support', 'Featured'],
    src: '//integratedlistening.com/events',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: null,
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasFocusCertification, hasSspCertification }) =>
      hasFocusCertification || hasSspCertification,
  },
  {
    id: '8fe46e1d-38f5-4214-9a9e-134532da0d36',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/10.png',
    resourceType: 'link',
    title: 'Case Study Submission Form',
    titleInHeader: true,
    shortDescription: 'Use this form to share story with us, we would love to hear it!',
    productCategory: ['SSP', 'ILS', 'Form', 'Research', 'Featured'],
    src: 'https://integratedlistening.com/about/case-study-submission/',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: null,
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasSspCertification, hasFocusCertification }) =>
      hasSspCertification || hasFocusCertification,
  },
  {
    id: '02fc44bd-c797-4c8d-8689-245f446e5682',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/21.png',
    resourceType: 'secure-storage-file',
    title: 'SSP Precautions',
    titleInHeader: true,
    shortDescription: 'A list of important precautions when delivering the SSP to Clients.',
    productCategory: ['SSP', 'File', 'Featured'],
    src: 'Provider/SSP+Precautions.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2022-04-18',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasSspCertification }) => hasSspCertification,
  },
  {
    id: '0d46db36-7151-4d7e-88ca-111a8518768c',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/39.png',
    resourceType: 'secure-storage-file',
    title: 'ILS Programs Summary',
    titleInHeader: true,
    shortDescription: 'A guide to using and combining the ILS Programs.',
    productCategory: ['ILS', 'Featured'],
    src: 'focus/ILS_Programs_Summary.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: null,
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasCompletedFocusCertification }) =>
      hasCompletedFocusCertification,
  },
  {
    id: '3ed582e7-24d3-4d9b-9a7f-f011e18b9ca9',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/6.png',
    resourceType: 'videos',
    title: 'Using the Unyte Health App',
    titleInHeader: true,
    shortDescription: 'Learn how to use the Unyte Health app.',
    productCategory: ['SSP', 'Getting Started'],
    src: '//player.vimeo.com/video/490064804',
    roles: ['client'],
    uploadDate: null,
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.CLIENT_ROLES),
    isEnabledByProductsStatus: ({ hasSspProducts }) => hasSspProducts,
  },
  {
    id: '4782391e-f728-47c6-a8df-7a2bff5613ea',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/11.png',
    resourceType: 'link',
    title: 'A Polyvagal Library',
    titleInHeader: true,
    shortDescription: 'Here are some resources to support your understanding of Polyvagal Theory.',
    productCategory: ['Link', 'Q&A', 'Research', 'Featured'],
    src: '//integratedlistening.com/a-polyvagal-library/',
    roles: ['provider_all_clients', 'provider', 'client'],
    uploadDate: null,
    isEnabledByRole: ({ userRoles }) =>
      userRoles &&
      (includesSome(userRoles, ROLES.PROVIDER_ROLES) ||
        includesSome(userRoles, ROLES.CLIENT_ROLES)),
    isEnabledByProductsStatus: ({ hasSspCertification, hasSspProducts }) =>
      hasSspCertification && hasSspProducts,
  },
  {
    id: 'ba727841-ecd2-436f-8c11-bbcc2e6eb858',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/12.png',
    resourceType: 'secure-storage-file',
    title: 'SSP Original Playlist',
    titleInHeader: true,
    shortDescription: 'A list of all songs in the SSP Original playlist.',
    productCategory: ['SSP', 'File', 'Playlist'],
    src: 'Provider/SSP_Original_Playlist.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: null,
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasSspCertification }) => hasSspCertification,
  },
  {
    id: '5f5a499d-3627-4f66-98ff-24cc19398967',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/13.png',
    resourceType: 'secure-storage-file',
    title: 'SSP Wonder Playlist',
    titleInHeader: true,
    shortDescription: 'A list of all songs in the SSP Wonder playlist.',
    productCategory: ['SSP', 'File', 'Playlist'],
    src: 'Provider/SSP_Wonder_Playlist.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: null,
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasSspCertification }) => hasSspCertification,
  },
  {
    id: '46173427-86a7-44cf-97b2-0958b03b9123',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/14.png',
    resourceType: 'secure-storage-file',
    title: 'SSP Classical Calm Playlist',
    titleInHeader: true,
    shortDescription: 'A list of all songs in the SSP Connect Calm playlist.',
    productCategory: ['SSP', 'File', 'Playlist'],
    src: 'Provider/SSP_Classical_Calm_Playlist.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: null,
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasSspCertification }) => hasSspCertification,
  },
  {
    id: '6eacaa3c-6422-4d14-84ff-0372e925a908',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/15.png',
    resourceType: 'secure-storage-file',
    title: 'SSP Groove Playlist',
    titleInHeader: true,
    shortDescription: 'A list of all songs in the SSP Groove playlist.',
    productCategory: ['SSP', 'Playlist'],
    src: 'Provider/SSP_Groove_Playlist.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2024-07-17',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasSspCertification }) => hasSspCertification,
  },
  {
    id: '0bd02863-7e5d-4bfa-8eea-57a3930430b2',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/16.png',
    resourceType: 'secure-storage-file',
    title: 'SSP Intake Form for Adults',
    titleInHeader: true,
    shortDescription: "A tool to help you better understand your adult Client's nervous system.",
    productCategory: ['SSP', 'Form', 'Assessment', 'Adult'],
    src: 'SSP+Intake+Form/UNYTE-ILS+SSP+INTAKE+FORM+-+ADULT+CLIENT+-+Apr+2023.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: null,
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasSspProducts }) => hasSspProducts,
  },
  {
    id: 'f009ac73-7e22-4299-8cbb-d555a7fa85ee',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/113.png',
    resourceType: 'secure-storage-file',
    title: 'Pediatric Symptom Checklist (PSC)',
    titleInHeader: true,
    shortDescription: 'Learn more about the development, use, and scoring of the PSC.',
    productCategory: ['SSP', 'Assessment'],
    src: 'Provider/Assessments/MU+Cards+-+PSC.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2022-10-05',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({
      hasCompletedSspCertification,
      hasSspProducts,
      hasCompletedFocusCertification,
      hasFocusProducts,
    }) =>
      (hasCompletedSspCertification && hasSspProducts) ||
      (hasCompletedFocusCertification && hasFocusProducts),
  },
  {
    id: 'b90280c5-b7c2-4b2d-ad8e-d11a90d7885b',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/114.png',
    resourceType: 'secure-storage-file',
    title: 'The Developmental Coordination Disorder Questionnaire (DCDQ)',
    titleInHeader: true,
    shortDescription: 'Learn more about the development, use, and scoring of the DCDQ.',
    productCategory: ['SSP', 'Assessment'],
    src: 'Provider/Assessments/MU+Cards+-+DCDQ.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2022-10-05',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({
      hasCompletedSspCertification,
      hasSspProducts,
      hasCompletedFocusCertification,
      hasFocusProducts,
    }) =>
      (hasCompletedSspCertification && hasSspProducts) ||
      (hasCompletedFocusCertification && hasFocusProducts),
  },
  {
    id: 'f55101f3-fdd5-4ec2-8017-fdaa84e87bf9',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/115.png',
    resourceType: 'secure-storage-file',
    title: 'Neuroception of Psychological Safety Scale (NPSS)',
    titleInHeader: true,
    shortDescription: 'Learn more about the development, use, and scoring of the NPSS.',
    productCategory: ['SSP', 'Assessment'],
    src: 'Provider/Assessments/MU+Cards+-+NPSS.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2022-10-05',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({
      hasCompletedSspCertification,
      hasSspProducts,
      hasCompletedFocusCertification,
      hasFocusProducts,
    }) =>
      (hasCompletedSspCertification && hasSspProducts) ||
      (hasCompletedFocusCertification && hasFocusProducts),
  },
  {
    id: 'd6c0d1e1-bbc9-47c5-9f8f-58a2b79987f8',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/17.png',
    resourceType: 'secure-storage-file',
    title: 'SSP Intake Form for Children (0-18)',
    titleInHeader: true,
    shortDescription: "A tool to help you better understand your child Client's nervous system.",
    productCategory: ['SSP', 'Form', 'Assessment', 'Child'],
    src: 'SSP+Intake+Form/UNYTE-ILS+SSP+INTAKE+FORM+-+CHILD+FORM+-+Apr+2023.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: null,
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasSspProducts }) => hasSspProducts,
  },
  {
    id: '8f7fd2b2-8277-4018-9674-9cdee1c82f44',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/29.png',
    resourceType: 'videos',
    title: 'SSP Q&A with Ana DoValle',
    titleInHeader: true,
    shortDescription: 'A recording of the SSP Q&A with Ana DoValle from July 7, 2020.',
    productCategory: ['SSP', 'Video', 'Q&A'],
    src: '//player.vimeo.com/video/436279552',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: null,
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasCompletedSspCertification }) => hasCompletedSspCertification,
  },
  {
    id: '2ba9cc92-a735-4a2a-b630-ce1972f94858',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/44.png',
    resourceType: 'secure-storage-file',
    title: 'Personal History Form - Children and Young Adults',
    titleInHeader: true,
    shortDescription:
      'An intake form to support the evaluation process for children and young adult Clients.',
    productCategory: ['ILS', 'Form'],
    src: 'focus/Personal+History+Form+-+Children+and+Young+Adults.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: null,
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasCompletedFocusCertification }) =>
      hasCompletedFocusCertification,
  },
  {
    id: 'a4095a89-ae18-42f0-9a19-9ec393a55b91',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/48.png',
    resourceType: 'secure-storage-file',
    title: 'MFA Program Guidelines',
    titleInHeader: true,
    shortDescription:
      'How to use the MFA to decide which ILS Programs are most appropriate for a Client.',
    productCategory: ['Assessment', 'ILS'],
    src: 'focus/MFA+Program+Guidelines.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: null,
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasCompletedFocusCertification }) =>
      hasCompletedFocusCertification,
  },
  {
    id: '1d273fd1-3100-42c7-a4ba-d9b5fe30a2f9',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/49.png',
    resourceType: 'secure-storage-file',
    title: 'MFA for Adolescents',
    titleInHeader: true,
    shortDescription: 'Measure of Foundational Abilities (MFA) assessment for Adolescents.',
    productCategory: ['Assessment', 'ILS', 'Adolescent'],
    src: 'Provider/Assessments/mfa_for_adolescent_2_1_.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: null,
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasCompletedFocusCertification }) =>
      hasCompletedFocusCertification,
  },
  {
    id: '6802c12d-5d9e-4d92-be61-bd16a908c073',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/50.png',
    resourceType: 'secure-storage-file',
    title: 'MFA for Adults',
    titleInHeader: true,
    shortDescription: 'Measure of Foundational Abilities (MFA) assessment for Adults.',
    productCategory: ['Assessment', 'ILS', 'Adult'],
    src: 'Provider/Assessments/mfa_for_adults_1_.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: null,
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasCompletedFocusCertification }) =>
      hasCompletedFocusCertification,
  },
  {
    id: '094f9d4a-0cb2-4c39-83ef-c1195d6ea2bc',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/51.png',
    resourceType: 'secure-storage-file',
    title: 'MFA for Grade School',
    titleInHeader: true,
    shortDescription: 'Measure of Foundational Abilities (MFA) assessment for Grade School.',
    productCategory: ['Assessment', 'ILS', 'Child'],
    src: 'Provider/Assessments/mfa_for_grade_school_2_1_.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: null,
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasCompletedFocusCertification }) =>
      hasCompletedFocusCertification,
  },
  {
    id: '9445e32c-023a-4034-9206-15799cb6dd78',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/52.png',
    resourceType: 'secure-storage-file',
    title: 'MFA for Preschool',
    titleInHeader: true,
    shortDescription: 'Measure of Foundational Abilities (MFA) assessment for Preschool.',
    productCategory: ['Assessment', 'ILS', 'Child'],
    src: 'Provider/Assessments/mfa_for_preschool_2_1_.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: null,
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasCompletedFocusCertification }) =>
      hasCompletedFocusCertification,
  },
  {
    id: '21ff6f1a-6c50-4901-9698-7c44597e09d5',
    pageTitle: 'Resource',
    resourceType: 'secure-storage-file',
    title: 'Processing and Regulation eBook',
    titleInHeader: true,
    shortDescription:
      'Find out why effective processing and regulation are the essential foundation for health.',
    productCategory: ['Featured'],
    src: 'e-book-why-effective-processing-and-regulation-2022-v2.pdf',
    roles: ['client', 'provider', 'provider_all_clients'],
    uploadDate: '2022-06-29',
    isEnabledByRole: ({ userRoles }) =>
      userRoles &&
      (includesSome(userRoles, ROLES.PROVIDER_ROLES) ||
        includesSome(userRoles, ROLES.CLIENT_ROLES)),
    isEnabledByProductsStatus: () => true,
    imgSrc: '/images/resources/cards/53.png',
  },
  {
    id: '29e5725e-0085-4c18-a17c-889021322c07',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/54.png',
    resourceType: 'secure-storage-file',
    title: 'Unyte Intake Form for Adults',
    titleInHeader: true,
    shortDescription:
      "A tool to help you better understand your client's nervous system and prepare for program delivery.",
    productCategory: ['SSP'],
    src: 'SSP+Intake+Form/SSP_Intake_Form_Adult_Client.pdf',
    provideFeedbackSrc:
      '//docs.google.com/forms/d/e/1FAIpQLSfRdhzbByyt9j5n6nAaMdwawhv8H71IrICD57xXh8KyDzidew/viewform',
    roles: ['client'],
    uploadDate: null,
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.CLIENT_ROLES),
    isEnabledByProductsStatus: ({ hasSspProducts }) => hasSspProducts,
  },
  {
    id: 'c99fb0f9-36d7-4711-8278-c8691061a7c0',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/55.png',
    resourceType: 'secure-storage-file',
    title: 'SSP Intake Form for Clients (Child)',
    titleInHeader: true,
    shortDescription:
      "A tool to help a Provider better understand your child's nervous system, to be completed by a caregiver.",
    productCategory: ['SSP'],
    src: 'SSP+Intake+Form/UNYTE-ILS+SSP+INTAKE+FORM+-+PARENT+FORM+-+Oct+2021.pdf',
    roles: ['client'],
    uploadDate: null,
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.CLIENT_ROLES),
    isEnabledByProductsStatus: ({ hasSspProducts }) => hasSspProducts,
  },
  {
    id: 'e3551338-25ce-4186-a38d-3315fe2ab184',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/56.png',
    productCategory: ['SSP'],
    resourceType: 'secure-storage-file',
    roles: ['client'],
    uploadDate: null,
    shortDescription:
      'A tool to help a Provider better understand your nervous system, designed for adolescents (ages 13-18).',
    src: 'SSP+Intake+Form/UNYTE-ILS+SSP+INTAKE+FORM+-+ADOLESCENT+(aged+13-18)+-+Oct+2021.pdf',
    title: 'SSP Intake Form for Clients (13-18)',
    titleInHeader: true,
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.CLIENT_ROLES),
    isEnabledByProductsStatus: ({ hasSspProducts, hasSspCertification }) =>
      hasSspProducts || hasSspCertification,
  },
  {
    id: '87385f97-2a44-4b63-9358-8fedf38fdbeb',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/148.png',
    resourceType: 'secure-storage-file',
    title: 'Body Perception Questionnaire (BPQ)',
    titleInHeader: true,
    shortDescription: 'Learn more about the development, use, and scoring of the BPQ.',
    productCategory: ['SSP', 'Assessment'],
    src: 'Provider/Assessments/MU+Cards+-+BPQ.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: null,
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({
      hasCompletedSspCertification,
      hasSspProducts,
      hasCompletedFocusCertification,
      hasFocusProducts,
    }) =>
      (hasCompletedSspCertification && hasSspProducts) ||
      (hasCompletedFocusCertification && hasFocusProducts),
  },
  {
    id: 'c2077268-e6d9-4fd5-b241-6387bc68e256',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/58.png',
    resourceType: 'secure-storage-file',
    title: 'Brain Body Center Sensory Scales (BBCSS)',
    titleInHeader: true,
    shortDescription: 'Learn more about the development, use, and scoring of the BBCSS.',
    productCategory: ['SSP', 'Assessment'],
    src: 'Provider/Assessments/MU+Cards+-+BBCSS.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: null,
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({
      hasCompletedSspCertification,
      hasSspProducts,
      hasCompletedFocusCertification,
      hasFocusProducts,
    }) =>
      (hasCompletedSspCertification && hasSspProducts) ||
      (hasCompletedFocusCertification && hasFocusProducts),
  },
  {
    id: 'ba112b47-408d-4677-972c-744142a6e6b5',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/59.png',
    resourceType: 'secure-storage-file',
    title: 'General Anxiety Disorder-7 (GAD-7)',
    titleInHeader: true,
    shortDescription: 'Learn more about the development, use, and scoring of the GAD-7.',
    productCategory: ['SSP', 'Assessment'],
    src: 'Provider/Assessments/MU+Cards+-+GAD-7.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: null,
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({
      hasCompletedSspCertification,
      hasSspProducts,
      hasCompletedFocusCertification,
      hasFocusProducts,
    }) =>
      (hasCompletedSspCertification && hasSspProducts) ||
      (hasCompletedFocusCertification && hasFocusProducts),
  },
  {
    id: 'ea44342e-6221-4bc0-8713-918402bff39c',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/60.png',
    resourceType: 'secure-storage-file',
    title: 'Patient Health Questionnaire (PHQ-9)',
    titleInHeader: true,
    shortDescription: 'Learn more about the development, use, and scoring of the PHQ-9.',
    productCategory: ['SSP', 'Assessment'],
    src: 'Provider/Assessments/MU+Cards+-+PHQ-9.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: null,
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({
      hasCompletedSspCertification,
      hasSspProducts,
      hasCompletedFocusCertification,
      hasFocusProducts,
    }) =>
      (hasCompletedSspCertification && hasSspProducts) ||
      (hasCompletedFocusCertification && hasFocusProducts),
  },
  {
    id: '3bd0067b-a191-4301-9313-e3021fa91eac',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/61.png',
    resourceType: 'secure-storage-file',
    title: 'PTSD Checklist for DSM-5 (PCL-5)',
    titleInHeader: true,
    shortDescription: 'Learn more about the development, use, and scoring of the PCL-5.',
    productCategory: ['SSP', 'Assessment'],
    src: 'Provider/Assessments/MU+Cards+-+PCL-5.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: null,
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({
      hasCompletedSspCertification,
      hasSspProducts,
      hasCompletedFocusCertification,
      hasFocusProducts,
    }) =>
      (hasCompletedSspCertification && hasSspProducts) ||
      (hasCompletedFocusCertification && hasFocusProducts),
  },
  {
    id: 'e499f7fc-5b1e-4317-8433-2e2ef515fac9',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/62.png',
    resourceType: 'link',
    title: 'Case Story Submission Form',
    titleInHeader: true,
    shortDescription: 'Share your story with us!',
    productCategory: ['Form', 'Featured'],
    src:
      '//docs.google.com/forms/d/e/1FAIpQLSdvZAxqSw0IKXDfZW--fHuF9EiVfayZCN5QUBfDMKW6oVYxTA/viewform',
    roles: ['client'],
    uploadDate: null,
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.CLIENT_ROLES),
    isEnabledByProductsStatus: () => true,
  },
  {
    id: '65df4718-3335-41e1-aca4-030af4b05986',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/64.png',
    resourceType: 'secure-storage-file',
    title: 'VoicePro Scripts',
    titleInHeader: true,
    shortDescription:
      'Example sentences to use as a guideline through various VoicePro activities.',
    productCategory: ['VoicePro'],
    src: 'VoicePro/VoicePro+Scripts.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: null,
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasVoiceProCertification }) => hasVoiceProCertification,
  },
  {
    id: 'd3ec29f4-5af6-4642-9945-1f2738f166c3',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/65.png',
    resourceType: 'secure-storage-file',
    title: 'VoicePro Interactive Language Worksheets',
    titleInHeader: true,
    shortDescription: "A worksheet to follow along with Client's use of the VoicePro.",
    productCategory: ['VoicePro'],
    src: 'VoicePro/VoicePro+Interactive+Language+Worksheets+10.19.21.docx.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: null,
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasVoiceProCertification }) => hasVoiceProCertification,
  },
  {
    id: 'd3449650-30a2-4345-af24-c17a95264b7a',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/66.png',
    resourceType: 'secure-storage-file',
    title: 'Hearing Devices Guide',
    titleInHeader: true,
    shortDescription: 'Instructions around Unyte Health products and assistive hearing devices.',
    productCategory: ['SSP', 'ILS'],
    src: 'Provider/Hearing_Devices_Guide_V2.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2022-11-25',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasSspCertification, hasFocusCertification }) =>
      hasSspCertification || hasFocusCertification,
  },
  {
    id: '17a12def-c3ca-452c-9756-5804ac37e642',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/79.png',
    resourceType: 'videos',
    title: 'SSP Q&A with Alex Freer',
    titleInHeader: true,
    shortDescription: 'A recording of the SSP Q&A with Alex Freer on June 2, 2021.',
    productCategory: ['SSP', 'Video', 'Q&A'],
    src: '//player.vimeo.com/video/670026938',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2022-04-01',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasCompletedSspCertification }) => hasCompletedSspCertification,
  },
  {
    id: '39827327-4839-4be2-be3a-6f5196e7de75',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/84.png',
    resourceType: 'videos',
    title: 'SSP Q&A with Liz Charles',
    titleInHeader: true,
    shortDescription: 'A recording of the SSP Q&A with Liz Charles on September 16, 2021.',
    productCategory: ['SSP', 'Video', 'Q&A'],
    src: '//player.vimeo.com/video/675151694',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2022-04-01',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasCompletedSspCertification }) => hasCompletedSspCertification,
  },
  {
    id: '15ba4c9b-63e5-4cc8-aea5-90ce2ce60260',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/85.png',
    resourceType: 'videos',
    title: 'SSP Q&A with Alex Freer',
    titleInHeader: true,
    shortDescription: 'A recording of the SSP Q&A with Alex Freer on September 22, 2021.',
    productCategory: ['SSP', 'Video', 'Q&A'],
    src: '//player.vimeo.com/video/675046885',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2022-04-01',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasCompletedSspCertification }) => hasCompletedSspCertification,
  },
  {
    id: 'ba047021-1f6d-48a6-818b-c5796914c54c',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/87.png',
    resourceType: 'videos',
    title: 'SSP Q&A with Doreen Hunt',
    titleInHeader: true,
    shortDescription:
      'A recording of the SSP Q&A General Questions with Doreen Hunt on October 1, 2021.',
    productCategory: ['SSP', 'Video', 'Q&A'],
    src: '//player.vimeo.com/video/677328866',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2022-04-01',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasCompletedSspCertification }) => hasCompletedSspCertification,
  },
  {
    id: '8c43ce4d-8a1d-454a-b3d4-7ed71db5844e',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/89.png',
    resourceType: 'secure-storage-file',
    title: 'EMDR and SSP: Combined Delivery Guidelines',
    titleInHeader: true,
    shortDescription:
      'Guidelines to integrate the SSP with EMDR therapy, by SSP-certified EMDRIA Approved Consultants.',
    productCategory: ['SSP', 'Delivery'],
    src: 'Provider/EMDR_SSP_Guidelines_2022.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2022-04-01',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasCompletedSspCertification, hasSspProducts }) =>
      hasCompletedSspCertification && hasSspProducts,
  },
  {
    id: 'f1e45be6-dde4-4f16-af3a-bbf1971d5516',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/99.png',
    resourceType: 'secure-storage-file',
    title: 'SSP Guiding Principles',
    titleInHeader: true,
    shortDescription: 'Guiding principles to safely deliver the SSP to Clients.',
    productCategory: ['SSP', 'Delivery'],
    src: 'Provider/SSP_Guiding_Principles.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2022-04-04',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasSspCertification }) => hasSspCertification,
  },
  {
    id: 'a59c187c-548a-412a-89ad-bd484c2dd05e',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/100.png',
    resourceType: 'videos',
    title: 'Clinical Conversations: EMDR and SSP',
    titleInHeader: true,
    shortDescription: 'A recording of the Clinical Conversations: EMDR and SSP on March 15, 2022.',
    productCategory: ['SSP', 'Video'],
    src: '//player.vimeo.com/video/694101067',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2022-04-07',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasCompletedSspCertification, hasSspProducts }) =>
      hasCompletedSspCertification && hasSspProducts,
  },
  {
    id: 'ff27fcd7-1e3d-4bd6-9ae0-7d2385e89e78',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/101.png',
    resourceType: 'videos',
    title: 'EMDR and SSP: Q&A for SSP Providers',
    titleInHeader: true,
    shortDescription: 'A recording of the EMDR and SSP Q&A on March 22, 2022.',
    productCategory: ['SSP', 'Video'],
    src: '//player.vimeo.com/video/696167299',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2022-04-07',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasCompletedSspCertification, hasSspProducts }) =>
      hasCompletedSspCertification && hasSspProducts,
  },
  {
    id: '2e502d44-db39-4cd7-b4ad-c120de64a255',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/103.png',
    resourceType: 'secure-storage-file',
    title: 'EMDR + SSP Clinical Preparatory Checklist',
    titleInHeader: true,
    shortDescription: 'A checklist for integrating the SSP into EMDR Therapy.',
    productCategory: ['SSP'],
    src: 'Provider/EMDR_%2B_SSP_Clinical_Checklists.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2022-05-20',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasCompletedSspCertification, hasSspProducts }) =>
      hasCompletedSspCertification && hasSspProducts,
  },
  {
    id: 'a5072ee0-218e-4bfe-ad76-09f2fc23548c',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/104.png',
    resourceType: 'secure-storage-file',
    title: 'SE + SSP Guidelines',
    titleInHeader: true,
    shortDescription: 'A guide to combining SE (Somatic Experiencing) and SSP in your practice.',
    productCategory: ['SSP', 'Delivery'],
    src: 'Provider/SE_%2B_SSP_Guidelines_-_June_2022.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2022-06-23',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasCompletedSspCertification, hasSspProducts }) =>
      hasCompletedSspCertification && hasSspProducts,
  },
  {
    id: 'c7913457-e5ce-4524-b243-657b06d7cd49',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/105.png',
    resourceType: 'videos',
    title: 'SE + SSP: Q&A for SSP Providers',
    titleInHeader: true,
    shortDescription: 'A recording of the SE and SSP Q&A on July 11, 2022.',
    productCategory: ['SSP', 'Video'],
    src: 'https://player.vimeo.com/video/732173954',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2022-07-21',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasCompletedSspCertification, hasSspProducts }) =>
      hasCompletedSspCertification && hasSspProducts,
  },
  {
    id: '8ffa9242-d9a2-4c14-9bb7-ab429abf89cb',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/106.png',
    resourceType: 'secure-storage-file',
    title: 'Infographic: Organizing Principles of Polyvagal Theory',
    titleInHeader: true,
    shortDescription: "A visual introduction to Dr. Stephen Porges' Polyvagal Theory.",
    productCategory: ['SSP'],
    src: 'Infographic_-_Organizing_Principles_of_PVT_-_2022_-_Alt.pdf',
    roles: ['provider_all_clients', 'provider', 'client'],
    uploadDate: '2022-08-03',
    isEnabledByRole: ({ userRoles }) =>
      userRoles &&
      (includesSome(userRoles, ROLES.PROVIDER_ROLES) ||
        includesSome(userRoles, ROLES.CLIENT_ROLES)),
    isEnabledByProductsStatus: ({ hasSspProducts }) => hasSspProducts,
  },
  {
    id: 'bc460ada-5af0-4e27-9253-db43882efbfe',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/107.png',
    resourceType: 'secure-storage-file',
    title: 'SSP Pathways: Connect, Core, Balance',
    titleInHeader: true,
    shortDescription: 'A guide to the three pathways of the Safe and Sound Protocol.',
    productCategory: ['SSP'],
    src: 'Three-Pathways-MU.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2022-08-11',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasSspCertification }) => hasSspCertification,
  },
  {
    id: '060998a2-0974-4536-9af3-b14dbf3300ba',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/110.png',
    resourceType: 'secure-storage-file',
    title: 'SSP Sensory Considerations',
    titleInHeader: true,
    shortDescription:
      'Sensory Considerations and suggestions to explore with clients as they progress through their SSP journey.',
    productCategory: ['SSP'],
    src: 'Provider/SSP_Sensory_Considerations.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2022-08-23',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasSspCertification }) => hasSspCertification,
  },
  {
    id: '6ca0f124-89d9-433d-a35b-a712cc96612d',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/111.png',
    resourceType: 'secure-storage-file',
    title: 'Autonomic Mapping Activity',
    titleInHeader: true,
    shortDescription:
      "Deb Dana's exercise that supports understanding of one’s nervous system signs, patterns and tendencies.",
    productCategory: ['SSP'],
    src: 'Provider/Autonomic_Mapping_Activity.pdf',
    roles: ['provider_all_clients', 'provider', 'client'],
    uploadDate: '2022-08-23',
    isEnabledByRole: ({ userRoles }) =>
      userRoles && includesSome(userRoles, [...ROLES.PROVIDER_ROLES, ...ROLES.CLIENT_ROLES]),
    isEnabledByProductsStatus: () => true,
  },
  {
    id: 'a59b5fe2-0da5-4f1b-98e8-a69f901a6131',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/116.png',
    resourceType: 'secure-storage-file',
    title: 'SSP Foundational Delivery Manual',
    titleInHeader: true,
    shortDescription: 'A practical guide for delivering the SSP.',
    productCategory: ['SSP', 'Manual', 'Featured', 'Delivery'],
    src: 'Provider/SSP_Foundational_Delivery_Manual.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2022-10-24',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasSspCertification }) => hasSspCertification,
  },
  {
    id: 'e63560b6-f830-44b2-93ce-07e49bb8b634',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/117.png',
    resourceType: 'videos',
    title: 'Setting up the ILS (Focus) Starter Pack',
    titleInHeader: true,
    shortDescription:
      'This video will help you set up your ILS Starter Pack so you can get started with the ILS.',
    productCategory: ['ILS', 'Getting Started', 'Featured', 'Video'],
    src: 'https://player.vimeo.com/video/770763382',
    roles: ['provider_all_clients', 'provider', 'client'],
    uploadDate: '2022-11-21',
    isEnabledByRole: ({ userRoles }) =>
      userRoles && includesSome(userRoles, [...ROLES.PROVIDER_ROLES, ROLES.CLIENT]),
    isEnabledByProductsStatus: ({
      hasCompletedFocusCertification,
      hasFocusProducts,
      clientHasFocus,
    }) => (hasCompletedFocusCertification && hasFocusProducts) || clientHasFocus,
  },
  {
    id: '422a2441-aafe-43d6-b57a-e9aecbd03755',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/120.png',
    resourceType: 'videos',
    title: 'ILS (Focus) Intro Video for providers',
    titleInHeader: true,
    shortDescription:
      'This video will provide an overview of the ILS components and give you confidence in delivery.',
    productCategory: ['ILS', 'Video', 'Getting Started', 'Featured'],
    src: 'https://player.vimeo.com/video/770763115',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2022-11-24',
    isEnabledByRole: ({ userRoles }) =>
      userRoles && includesSome(userRoles, [...ROLES.PROVIDER_ROLES]),
    isEnabledByProductsStatus: ({ hasCompletedFocusCertification, hasFocusProducts }) =>
      hasCompletedFocusCertification && hasFocusProducts,
  },
  {
    id: 'abf5061b-4331-40f1-a091-3963b0e33509',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/120.png',
    resourceType: 'videos',
    title: 'ILS (Focus) Intro Video for clients',
    titleInHeader: true,
    shortDescription: 'This video will provide an overview of the ILS components..',
    productCategory: ['ILS', 'Video', 'Getting Started', 'Featured'],
    src: 'https://player.vimeo.com/video/770794714',
    roles: ['client'],
    uploadDate: '2022-11-24',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.CLIENT_ROLES),
    isEnabledByProductsStatus: ({ clientHasFocus }) => clientHasFocus,
  },
  {
    id: '00bbe3d3-ba13-4a29-bfdf-58c9f890311f',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/122.png',
    resourceType: 'videos',
    title: 'How to use the Activity Library',
    titleInHeader: true,
    shortDescription: 'A guide of suggested activities to accompany ILS listening sessions.',
    productCategory: ['ILS', 'Getting Started', 'Featured', 'Video'],
    src: 'https://player.vimeo.com/video/777099127',
    roles: ['provider_all_clients', 'provider', 'client'],
    uploadDate: '2022-11-24',
    isEnabledByRole: () => true,
    isEnabledByProductsStatus: ({
      hasCompletedFocusCertification,
      hasFocusProducts,
      clientHasFocus,
    }) => (hasCompletedFocusCertification && hasFocusProducts) || clientHasFocus,
  },
  {
    id: 'e5fdc97c-15ae-4dc6-ac6c-33177ff3232b',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/123.png',
    resourceType: 'secure-storage-file',
    title: 'Foundational SSP Training Notes',
    titleInHeader: true,
    shortDescription:
      'A helpful resource to take notes as you progress through the Foundational SSP Training.',
    productCategory: ['SSP'],
    src: 'Provider/SSP_Certification_Notes.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2022-12-07',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasSspCertification }) => hasSspCertification,
  },
  {
    id: '926ef839-7b33-45ce-b7e0-d1c64dd05dd3',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/124.png',
    resourceType: 'secure-storage-file',
    title: 'SSP Classical Flow Playlist',
    titleInHeader: true,
    shortDescription: 'A list of all songs in the SSP Classical Flow playlist.',
    productCategory: ['SSP'],
    src: 'Provider/SSP_Classical_Flow_Playlist.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2022-12-15',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasSspCertification }) => hasSspCertification,
  },
  {
    id: 'db26aacd-1b59-4ea6-9474-a41373059f98',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/15.png',
    resourceType: 'secure-storage-file',
    title: 'SSP Freely Playlist',
    titleInHeader: true,
    shortDescription: 'A list of all songs in the SSP Freely playlist.',
    productCategory: ['SSP', 'File', 'Playlist'],
    src: 'Provider/SSP_Freely_Playlist.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2024-03-26',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasSspCertification }) => hasSspCertification,
  },
  {
    id: '8c5ee41b-faf5-474c-a928-1b352ffea1f6',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/125.png',
    resourceType: 'secure-storage-file',
    title: 'Setting the Foundation for EMDR Therapy',
    titleInHeader: true,
    shortDescription:
      'Introductory eBook: How a Polyvagal approach is key to a client’s regulation and engagement with EMDR.',
    productCategory: ['SSP'],
    src: 'Provider/A_Polyvagal_Approach_to_Setting_the_Foundation_for_EMDR_Therapy.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2022-12-30',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasCompletedSspCertification }) => hasCompletedSspCertification,
  },
  {
    id: 'ee72e504-b7c1-402f-a64e-af190ac11544',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/151.png',
    resourceType: 'link',
    title: 'A Conversation with Drs. Porges and Gabor Maté',
    titleInHeader: true,
    shortDescription:
      'In this video, the thought leaders discuss humanity and sociality and the impact of safety in society.',
    productCategory: ['Video', 'Featured', 'SSP'],
    src: 'https://player.vimeo.com/video/785049848',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2022-12-31',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: () => true,
  },
  {
    id: 'bcc052b1-aa8b-4745-aefc-cf699e8a2bbd',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/127.png',
    resourceType: 'secure-storage-file',
    title: 'ILS Guiding Principles',
    titleInHeader: true,
    shortDescription: 'A short list of best practices when delivering ILS Programs to clients.',
    productCategory: ['ILS'],
    src: 'focus/ILS_Guiding_Principles.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2023-01-27',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasFocusCertification }) => hasFocusCertification,
  },
  {
    id: '72079701-9134-45fc-bf3e-22fc92fc41f8',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/129.png',
    resourceType: 'videos',
    title: 'Clinical Conversations: Unyte Health Clinical Advisory Board',
    titleInHeader: true,
    shortDescription: 'The SSP Provider Journey — Initiation, Integration, and Innovation.',
    productCategory: ['SSP', 'Video'],
    src: '//player.vimeo.com/video/823763932',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2023-05-05',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasSspCertification }) => hasSspCertification,
  },
  {
    id: '6d39f1b2-5a0f-4313-a2ab-12ef42c3911f',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/130.png',
    resourceType: 'secure-storage-file',
    title: 'SSP Provider Peer-to-Peer Listening Guidelines',
    titleInHeader: true,
    shortDescription:
      'Peer-to-Peer listening is a way for certified SSP providers to experience the SSP with support from a peer.',
    productCategory: ['SSP'],
    src: 'Provider/Provider+Peer-to-Peer+Listening+Guidelines+2023-03.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2023-05-05',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasCompletedSspCertification, hasSspProducts }) =>
      hasCompletedSspCertification && hasSspProducts,
  },
  {
    id: '33b0a49e-5fc4-411a-8d4a-dc7dcbe110b2',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/131.png',
    resourceType: 'secure-storage-file',
    title: 'SSP Informed Consent Guide',
    titleInHeader: true,
    shortDescription:
      'A checklist, template, and guide to safely and effectively support SSP participants prior to and throughout delivery.',
    productCategory: ['SSP', 'Getting Started'],
    src: 'Provider/Informed_Consent_Guide.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2023-05-16',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasCompletedSspCertification, hasSspProducts }) =>
      hasCompletedSspCertification && hasSspProducts,
  },
  {
    id: 'fad63c61-c688-4806-bd4f-a9ebbb274e2a',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/132.png',
    resourceType: 'secure-storage-file',
    title: 'SSP Demo Session Guide',
    titleInHeader: true,
    shortDescription: 'A SSP session guide to demonstrate possible delivery topics and structure.',
    productCategory: ['SSP', 'Getting Started'],
    src: 'Provider/SSP_Demo_Session_Guide.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2023-05-16',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasSspCertification }) => hasSspCertification,
  },
  {
    id: 'c4fc8f0f-3d97-46af-abf9-b91d747d4420',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/153.png',
    resourceType: 'videos',
    title: 'Interview: Somatics, Attachment, Parts Work and Resonance',
    titleInHeader: true,
    shortDescription:
      'An exclusive interview with Paula Scatoloni, LCSW, SEP, and Kim Barthel, OTR/L, C/NDT.',
    productCategory: ['SSP', 'Video'],
    src: '//player.vimeo.com/video/834420861',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2023-06-11',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasSspCertification }) => hasSspCertification,
  },
  {
    id: '04f6161c-0b5c-46e5-9cba-1d3edd20f485',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/134.png',
    resourceType: 'secure-storage-file',
    title: 'Tinnitus Guide',
    titleInHeader: true,
    shortDescription:
      'A helpful reference for SSP providers working with clients experiencing tinnitus.',
    productCategory: ['SSP'],
    src: 'Provider/Unyte_Resource_-_Tinnitus_Guide.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2023-06-11',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasSspCertification }) => hasSspCertification,
  },
  {
    id: 'bf5c9864-28ae-4db3-bde5-204b9ad16eac',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/135.png',
    resourceType: 'secure-storage-file',
    title: 'ILS Listener Guide',
    titleInHeader: true,
    shortDescription: 'Tips and recommendations for clients who participate in the ILS.',
    productCategory: ['ILS'],
    src: 'focus/ILS_Client_Listener_Guide.pdf',
    roles: ['provider_all_clients', 'provider', 'client'],
    uploadDate: '2023-07-10',
    isEnabledByRole: ({ userRoles }) =>
      userRoles && includesSome(userRoles, [...ROLES.PROVIDER_ROLES, ...ROLES.CLIENT_ROLES]),
    isEnabledByProductsStatus: ({
      hasCompletedFocusCertification,
      hasFocusProducts,
      clientHasFocus,
    }) => (hasCompletedFocusCertification && hasFocusProducts) || clientHasFocus,
  },
  {
    id: '00225dbb-7feb-4eab-b237-009e6e2daf21',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/150.png',
    resourceType: 'secure-storage-file',
    title: 'Adverse Childhood Experiences (ACE)',
    titleInHeader: true,
    shortDescription:
      'Learn more about the development, use, and scoring of the ACE questionnaire.',
    productCategory: ['SSP', 'Assessment'],
    src: 'Provider/adverse_childhood_experiences.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2023-07-10',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasCompletedSspCertification, hasSspProducts }) =>
      hasCompletedSspCertification && hasSspProducts,
  },
  {
    id: '0d561082-47b1-4276-bf7b-78bbe33a393f',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/137.png',
    resourceType: 'videos',
    title: 'Guiding You from Theory to Practice',
    titleInHeader: true,
    shortDescription: 'Core principles of SSP delivery, experiential tools and the providers role.',
    productCategory: ['SSP', 'Video'],
    src: 'https://player.vimeo.com/video/844677967',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2023-07-19',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasCompletedSspCertification, hasSspProducts }) =>
      hasCompletedSspCertification && hasSspProducts,
  },
  {
    id: '2218103c-e9bb-4b3b-b6c0-3f4e3c9a85aa',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/138.png',
    resourceType: 'videos',
    title: 'SSP Client Demonstration and Analysis',
    titleInHeader: true,
    shortDescription:
      'SSP delivery demonstration through practical application analysis & case consultation.',
    productCategory: ['Getting Started', 'SSP', 'Video'],
    src: 'https://player.vimeo.com/video/850323275',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2023-08-02',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasCompletedSspCertification, hasSspProducts }) =>
      hasCompletedSspCertification && hasSspProducts,
  },
  {
    id: 'e66b657f-d052-4511-9180-a779cb4fc2e9',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/154.png',
    resourceType: 'secure-storage-file',
    title: 'Heart Rate Variability (HRV) Guide',
    titleInHeader: true,
    shortDescription:
      'A helpful resource for Unyte Health providers integrating HRV into their program delivery.',
    productCategory: ['SSP', 'ILS'],
    src: 'Provider/Unyte+Resource+-+HRV+Guide.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2023-08-21',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({
      hasCompletedSspCertification,
      hasSspProducts,
      hasCompletedFocusCertification,
      hasFocusProducts,
    }) =>
      (hasCompletedSspCertification && hasSspProducts) ||
      (hasCompletedFocusCertification && hasFocusProducts),
  },
  {
    id: '397a4944-95e2-441f-9ad6-ae578312ad15',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/140.png',
    resourceType: 'secure-storage-file',
    title: 'Child and Adolescent Trauma Screen (CATS-C)',
    titleInHeader: true,
    shortDescription: 'Learn more about the development, use, and scoring of the CATS-C.',
    productCategory: ['SSP', 'Assessment'],
    src: 'Provider/Assessments/MU+Cards+-+CATS.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2023-10-12',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasCompletedSspCertification, hasSspProducts }) =>
      hasCompletedSspCertification && hasSspProducts,
  },
  {
    id: '5e591e14-7120-4263-822d-12455cd33596',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/152.png',
    resourceType: 'secure-storage-file',
    title: 'Nervous System Regulating Activities for Children E-book',
    titleInHeader: true,
    shortDescription:
      'Activities to help young clients build regulatory capacity through development.',
    productCategory: ['SSP', 'ILS'],
    src: 'Provider/Nervous_System_Regulatory_Activities_for_Children.pdf',
    roles: ['provider_all_clients', 'provider', 'client'],
    uploadDate: '2023-10-24',
    isEnabledByRole: () => true,
    isEnabledByProductsStatus: ({
      hasCompletedSspCertification,
      hasSspProducts,
      hasCompletedFocusCertification,
      hasFocusProducts,
    }) =>
      hasCompletedSspCertification ||
      hasSspProducts ||
      hasCompletedFocusCertification ||
      hasFocusProducts,
  },
  {
    id: 'f96b1ad7-ff16-4e61-bf77-4c16f4d5d08d',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/57.png',
    resourceType: 'videos',
    title: 'What to Expect From Your SSP Experience',
    titleInHeader: true,
    shortDescription:
      'A short introduction to the SSP, listening sessions, and what to expect from your SSP provider.',
    productCategory: ['SSP', 'Video', 'Getting Started', 'Marketing'],
    src: '//player.vimeo.com/video/917187209',
    roles: ['provider_all_clients', 'provider', 'client'],
    uploadDate: '2024-03-04',
    isEnabledByRole: ({ userRoles }) =>
      userRoles && includesSome(userRoles, [...ROLES.PROVIDER_ROLES, ...ROLES.CLIENT_ROLES]),
    isEnabledByProductsStatus: ({ hasSspCertification, hasSspProducts }) =>
      hasSspCertification || hasSspProducts,
  },
  {
    id: 'c132f364-9ea1-444e-a7fb-4531d9bf7987',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/58.png',
    resourceType: 'secure-storage-file',
    title: 'Ways to Regulate Poster (Children)',
    titleInHeader: true,
    shortDescription:
      'Use this poster to compliment the Nervous System Regulating Activities for Children ebook.',
    productCategory: ['SSP', 'ILS'],
    src: 'Ways_to_Regulate_Poster_Children.pdf',
    roles: ['client', 'provider', 'provider_all_clients'],
    uploadDate: '2024-10-30',
    isEnabledByRole: ({ userRoles }) =>
      userRoles && includesSome(userRoles, [...ROLES.PROVIDER_ROLES, ...ROLES.CLIENT_ROLES]),
    isEnabledByProductsStatus: () => true,
  },
  {
    id: 'd59e74a1-78c3-44f2-84a1-9f3cfb581bdb',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/158.png',
    resourceType: 'secure-storage-file',
    title: 'Ways to Regulate Poster (Adults)',
    titleInHeader: true,
    shortDescription:
      'Use this poster to compliment the Nervous System Regulating Activities e-book.',
    productCategory: ['SSP', 'ILS'],
    src: 'Ways_to_Regulate_Poster_Adults.pdf',
    roles: ['client', 'provider', 'provider_all_clients'],
    uploadDate: '2024-12-11',
    isEnabledByRole: ({ userRoles }) =>
      userRoles && includesSome(userRoles, [...ROLES.PROVIDER_ROLES, ...ROLES.CLIENT_ROLES]),
    isEnabledByProductsStatus: () => true,
  },
  {
    id: 'bff27f1a-2c01-465a-947d-dc25d04baebb',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/155.png',
    resourceType: 'secure-storage-file',
    title: 'Your SSP Toolkit',
    titleInHeader: true,
    shortDescription:
      'Essential resources to help guide you through your SSP listening experience.',
    productCategory: ['SSP', 'Getting Started', 'Marketing'],
    src: 'Client/SSP_Client_Toolkit.pdf',
    roles: ['provider_all_clients', 'provider', 'client'],
    uploadDate: '2024-10-28',
    isEnabledByRole: ({ userRoles }) =>
      userRoles && includesSome(userRoles, [...ROLES.PROVIDER_ROLES, ...ROLES.CLIENT_ROLES]),
    isEnabledByProductsStatus: ({ hasSspProducts }) => hasSspProducts,
  },
  {
    id: '52b4e5b0-308c-4e9e-a33e-6bab53786c9e',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/156.png',
    resourceType: 'secure-storage-file',
    title: 'SSP Headphone Guide',
    titleInHeader: true,
    shortDescription: 'A guide to SSP headphone recommendations.',
    productCategory: ['SSP', 'Delivery'],
    src: 'SSP_Headphone_Guide.pdf',
    roles: ['provider_all_clients', 'provider', 'client'],
    uploadDate: '2024-10-31',
    isEnabledByRole: ({ userRoles }) =>
      userRoles && includesSome(userRoles, [...ROLES.PROVIDER_ROLES, ...ROLES.CLIENT_ROLES]),
    isEnabledByProductsStatus: ({ hasSspProducts }) => hasSspProducts,
  },
  {
    id: 'bc7bf8a3-670b-4584-b353-965fe7640961',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/157.png',
    resourceType: 'secure-storage-file',
    title: 'Program Delivery Checklist',
    titleInHeader: true,
    shortDescription: 'A start-to-finish delivery guide through Unyte programs.',
    productCategory: ['Getting Started', 'SSP', 'Provider Toolkit'],
    src: 'Provider/SSP_Provider_Checklist.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2024-12-11',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasSspCertification }) => hasSspCertification,
  },
  {
    id: '4116985c-fa33-4641-8d1e-410f6bca58fc',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/159.png',
    resourceType: 'secure-storage-file',
    title: 'Autonomic Mapping Activity (Children)',
    titleInHeader: true,
    shortDescription:
      "A pediatric adapted version of Deb Dana's exercise that supports understanding of brain body states.",
    productCategory: ['Getting Started', 'Provider Toolkit', 'SSP'],
    src: 'Pediatric_Autonomic_Mapping_Activity.pdf',
    roles: ['provider_all_clients', 'provider', 'client'],
    uploadDate: '2024-12-18',
    isEnabledByRole: ({ userRoles }) =>
      userRoles && includesSome(userRoles, [...ROLES.PROVIDER_ROLES, ROLES.CLIENT]),
    isEnabledByProductsStatus: () => true,
  },
]

const PROVIDER_TOOLKIT_RESOURCES = [
  {
    id: '7c867a11-7247-4b3c-b830-6684f22270f5',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/provider-toolkit/Case-Consultation.png',
    resourceType: 'link',
    title: 'Case Support and Consultations',
    titleInHeader: true,
    shortDescription: 'Do you need support with a case? We’re here to help!',
    productCategory: ['SSP', 'ILS', 'Form', 'Support', 'Featured', 'Delivery', 'Provider Toolkit'],
    src: 'https://integratedlistening.com/about/case-support-and-consultations/',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: null,
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({
      hasCompletedFocusCertification,
      hasCompletedSspCertification,
      hasSspProducts,
    }) => (hasCompletedSspCertification && hasSspProducts) || hasCompletedFocusCertification,
  },
  {
    id: '4a2101b3-9034-4444-b502-d4c47543eea2',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/provider-toolkit/Provider-Toolkit.png',
    resourceType: 'secure-storage-file',
    title: 'SSP Provider Toolkit (PDF)',
    titleInHeader: true,
    shortDescription:
      'Save this clickable document to your desktop for quick access to key resources in this toolkit.',
    productCategory: [
      'SSP',
      'Link',
      'Featured',
      'Getting Started',
      'Provider Toolkit',
      'Marketing',
    ],
    src: 'Provider/SSP_Provider_Toolkit.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2022-12-30',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasSspCertification }) => hasSspCertification,
  },
  {
    id: '418b1373-e4d5-4741-93a1-4438cc3d6e0c',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/provider-toolkit/Intake-Forms.png',
    resourceType: 'secure-storage-file',
    title: 'SSP Intake Form for Providers',
    titleInHeader: true,
    shortDescription:
      'Self-reflection, scoring, and recommendations to help you gain greater insight about your Client and their body/brain system.',
    productCategory: ['SSP', 'Form', 'Assessment', 'Delivery', 'Provider Toolkit'],
    src: 'SSP+Intake+Form/UNYTE-ILS+SSP+INTAKE+FORM+-+PROVIDER+-+Oct+2021.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: null,
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasCompletedSspCertification, hasSspProducts }) =>
      hasCompletedSspCertification && hasSspProducts,
  },
  {
    id: '0d3a7345-924d-4fc6-9423-383102075ea4',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/provider-toolkit/Help-Center.png',
    resourceType: 'externalLink',
    title: 'Unyte Help Center',
    titleInHeader: true,
    shortDescription: 'The best place to visit if you have any questions about using our products.',
    productCategory: ['Delivery', 'Tech Support', 'Provider Toolkit'], // empty chip to preserve spacing
    src: 'https://support.unyte.com',
    roles: ['client', 'provider_all_clients', 'provider'],
    uploadDate: '2022-04-01',
    isEnabledByRole: () => true,
    isEnabledByProductsStatus: () => true,
  },
  {
    id: 'aa4fc01b-640f-4818-814c-acca05af4432',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/provider-toolkit/Provider-Map.png',
    resourceType: 'link',
    title: 'Client Connections',
    titleInHeader: true,
    shortDescription:
      'An opportunity to connect clients seeking SSP and ILS programs to certified providers.',
    productCategory: ['Marketing', 'Provider Toolkit'],
    src: 'https://integratedlistening.com/provider-listing/',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2022-06-27',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({
      hasCompletedSspCertification,
      hasSspProducts,
      hasCompletedFocusCertification,
      hasFocusProducts,
    }) =>
      (hasCompletedSspCertification && hasSspProducts) ||
      (hasCompletedFocusCertification && hasFocusProducts),
  },
  {
    id: 'd4bb237b-eae1-4355-8dc7-c69becdd9ae5',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/provider-toolkit/Live-Troubleshooting.png',
    resourceType: 'link',
    title: 'Live Troubleshooting',
    titleInHeader: true,
    shortDescription:
      'Schedule time with a Client Success representative to resolve technical issues.',
    productCategory: ['Tech Support', 'Provider Toolkit'],
    src: 'https://connect.unyte.com/meetings/unyte/technical-support',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2022-06-27',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: () => true,
  },
  {
    id: 'eba9ce13-1af5-4870-b0d8-c27006e715c7',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/provider-toolkit/Nervous-System-Regulating-Activities.png',
    resourceType: 'secure-storage-file',
    title: 'Nervous System Regulating Activities E-book',
    titleInHeader: true,
    shortDescription: 'Movement and touch activities to ground Clients experiencing dysregulation.',
    productCategory: ['SSP', 'ILS', 'Client Education', 'Provider Toolkit'],
    src: 'Provider/Nervous_System_Regulating_Activities_E-book.pdf',
    roles: ['provider_all_clients', 'provider', 'client'],
    uploadDate: '2022-04-01',
    isEnabledByRole: () => true,
    isEnabledByProductsStatus: () => true,
  },
  {
    id: '715cbc87-b1c0-48b3-9aa9-bb34b68fc78e',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/provider-toolkit/SSP-Titration-Tool.png',
    resourceType: 'titration-tool',
    title: 'SSP Program Planning Tool',
    titleInHeader: true,
    shortDescription:
      'An interactive tool to provide program recommendations based on client factors.',
    productCategory: ['SSP', 'Provider Toolkit', 'Delivery'],
    src: '',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2023-08-10',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasSspCertification }) => hasSspCertification,
  },
  {
    id: 'b6341063-5957-4c76-863f-2a2b27af0dab',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/provider-toolkit/Pricing-the-SSP.png',
    resourceType: 'secure-storage-file',
    title: 'Pricing the SSP for Your Practice',
    titleInHeader: true,
    shortDescription: 'A guide to help determine possible pricing of the SSP for your practice.',
    productCategory: ['SSP', 'Pricing', 'Provider Toolkit', 'Marketing'],
    src: 'Provider/Pricing_for_SSP.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2022-09-20',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasSspCertification }) => hasSspCertification,
  },
  {
    id: '0aee194f-006f-435c-8f0a-6859225326e0',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/provider-toolkit/Unyte-Logos.png',
    resourceType: 'secure-storage-file',
    downloadFile: true,
    title: 'Unyte Health Logo Images',
    titleInHeader: true,
    shortDescription:
      'Use these logos to show that you offer Unyte Health therapeutic programs in your practice.',
    productCategory: ['SSP', 'Provider Toolkit', 'Marketing'],
    src: 'Provider/Unyte+Logos+-+SSP+Provider+Toolkit.zip',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2022-09-20',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasSspCertification }) => hasSspCertification,
  },
  {
    id: '0a32a5ca-c5d1-4cd9-a4f0-e9415ab91735',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/provider-toolkit/Certification-Badges.png',
    resourceType: 'secure-storage-file',
    title: 'SSP Provider Marketing Guide',
    titleInHeader: true,
    shortDescription:
      'Use this checklist to let potential clients know that you’re a certified SSP provider.',
    productCategory: ['SSP', 'Provider Toolkit', 'Marketing'],
    src: 'Provider/SSP_Provider_Marketing_Guide.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2024-02-22',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasSspCertification }) => hasSspCertification,
  },
  {
    id: '7e25bdee-b582-4c5f-8835-b74a006b00f8',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/provider-toolkit/Brand-Images.png',
    resourceType: 'secure-storage-file',
    downloadFile: true,
    title: 'Unyte Health Brand Images',
    titleInHeader: true,
    shortDescription: 'Use these Unyte Health branded images in your SSP marketing materials.',
    productCategory: ['SSP', 'Provider Toolkit', 'Marketing'],
    src: 'Provider/Marketing+Images+-+SSP+Provider+Toolkit.zip',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2022-09-20',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasSspCertification }) => hasSspCertification,
  },
  {
    id: 'aed81f3e-57ce-4f15-9122-4466682882a6',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/provider-toolkit/Certification-Badges.png',
    resourceType: 'secure-storage-file',
    downloadFile: true,
    title: 'Certification and Provider Badges',
    titleInHeader: true,
    shortDescription: 'Display these badges to show your SSP provider and/or certification status.',
    productCategory: ['SSP', 'Provider Toolkit', 'Marketing'],
    src: 'Provider/Certification+Badges+-+SSP+Provider+Toolkit.zip',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2022-09-20',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasCompletedSspCertification, hasSspProducts }) =>
      hasCompletedSspCertification && hasSspProducts,
  },
  {
    id: '29a157b4-577c-4257-84b4-c432a8a65ba9',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/128.png',
    resourceType: 'secure-storage-file',
    title: 'ILS Marketing Content',
    titleInHeader: true,
    shortDescription: 'Market your practice and the ILS using this templated text.',
    productCategory: ['ILS', 'Provider Toolkit', 'Marketing'],
    src: 'focus/ILS_Marketing_Content.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2023-03-25',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasCompletedFocusCertification, hasFocusProducts }) =>
      hasCompletedFocusCertification && hasFocusProducts,
  },
  {
    id: 'a18141ae-6f89-42aa-ba13-ef2f490b6204',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/provider-toolkit/Bone-Conducting-Headphones.png',
    resourceType: 'secure-storage-file',
    title: 'What is Bone Conduction?',
    titleInHeader: true,
    shortDescription: 'A visual introduction to ILS Bone Conduction.',
    productCategory: ['ILS', 'Provider Toolkit', 'Marketing'],
    src: 'focus/What_is_Bone_Conduction_V2.pdf',
    roles: ['provider_all_clients', 'provider', 'client'],
    uploadDate: '2023-03-25',
    isEnabledByRole: ({ userRoles }) =>
      userRoles && includesSome(userRoles, [...ROLES.PROVIDER_ROLES, ...ROLES.CLIENT_ROLES]),
    isEnabledByProductsStatus: ({ hasFocusCertification, clientHasFocus }) =>
      hasFocusCertification || clientHasFocus,
  },
  {
    id: 'b12f7e32-5ee9-48eb-bd0a-4972a83b1ddc',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/133.png',
    resourceType: 'secure-storage-file',
    title: 'ILS Provider Toolkit',
    titleInHeader: true,
    shortDescription:
      'Tools to help you understand the ILS, educate your clients, and add value to your practice.',
    productCategory: ['ILS', 'Provider Toolkit', 'Marketing'],
    src: 'focus/ILS_Provider_Toolkit.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2023-05-16',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasFocusCertification }) => hasFocusCertification,
  },
  {
    id: '5296a84c-8616-41fe-b0f4-6d42ac1f96ab',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/134.png',
    resourceType: 'link',
    title: 'What is the SSP?',
    titleInHeader: true,
    shortDescription:
      'A client-facing site to help educate on the SSP, how it works and the science behind it.',
    productCategory: ['SSP', 'Marketing'],
    src: 'https://www.whatisthessp.com',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2023-08-25',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasSspCertification }) => hasSspCertification,
  },
  {
    id: '29c7c23c-5536-4060-af95-26e9f30d3a45',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/135.png',
    resourceType: 'link',
    title: 'What is the SSP?',
    titleInHeader: true,
    shortDescription:
      'Get an overview of the SSP, how it works and the science behind the listening therapy.',
    productCategory: ['SSP', 'Marketing'],
    src: 'https://www.whatisthessp.com/',
    roles: ['client'],
    uploadDate: '2023-08-25',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.CLIENT_ROLES),
    isEnabledByProductsStatus: ({ hasSspProducts }) => hasSspProducts,
  },
  {
    id: '8594f154-199a-4e5f-a0bd-4f8a33bdcc71',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/136.png',
    resourceType: 'secure-storage-file',
    title: 'Unyte Health Evidence Summary',
    titleInHeader: true,
    shortDescription:
      'A summary of clinical trials and real world evidence supporting the SSP and ILS.',
    productCategory: ['SSP', 'ILS'],
    src: 'Provider/Unyte_Evidence_Summary.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2023-08-29',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasSspCertification }) => hasSspCertification,
  },
  {
    id: 'b62f7e48-8d25-4e16-85c1-898ea5392331',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/137.png',
    resourceType: 'secure-storage-file',
    title: 'Encouraging Headphone Tolerance',
    titleInHeader: true,
    shortDescription:
      'A helpful resource for ILS/SSP Providers, offering strategies to address headphone intolerance.',
    productCategory: ['SSP', 'ILS', 'Delivery'],
    src: 'Provider/Encouraging_Headphone_Tolerance.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2023-08-29',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({
      hasCompletedSspCertification,
      hasSspProducts,
      hasCompletedFocusCertification,
      hasFocusProducts,
    }) =>
      (hasCompletedSspCertification && hasSspProducts) ||
      (hasCompletedFocusCertification && hasFocusProducts),
  },
  {
    id: 'df69ad21-3211-4b1e-b2c7-da30e3e76f4d',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/138.png',
    resourceType: 'secure-storage-file',
    title: 'Medical Provider Communication Guide',
    titleInHeader: true,
    shortDescription:
      'Guide and template for SSP providers collaborating with medical teams on complex client cases beyond their scope.',
    productCategory: ['SSP'],
    src: 'Provider/Medical_Communication_Guide.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2023-08-29',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasCompletedSspCertification, hasSspProducts }) =>
      hasCompletedSspCertification && hasSspProducts,
  },
  {
    id: 'a3fa6675-721a-4e45-8d57-c357011c5264',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/139.png',
    resourceType: 'secure-storage-file',
    title: 'SSP + OT/SIP Combined Delivery Guidelines',
    titleInHeader: true,
    shortDescription: 'Combined delivery of sensory-based OT interventions and the SSP.',
    productCategory: ['SSP', 'Delivery'],
    src: 'Provider/OTSIP_SSP_Guidelines_2023.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2023-09-26',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasCompletedSspCertification, hasSspProducts }) =>
      hasCompletedSspCertification && hasSspProducts,
  },
  {
    id: 'f5dfb068-1d6a-4e82-83dd-4d5d49cee596',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/141.png',
    resourceType: 'secure-storage-file',
    title: 'SSP Group Delivery Guidelines',
    titleInHeader: true,
    shortDescription: 'A guide to safe and effective SSP delivery in groups.',
    productCategory: ['SSP', 'Delivery'],
    src: 'Provider/Group_Delivery_Guidelines_-_Nov_2023.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2023-12-04',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasCompletedSspCertification, hasSspProducts }) =>
      hasCompletedSspCertification && hasSspProducts,
  },
  {
    id: '7c733bc0-e5b0-47e2-a1df-b03e650417d9',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/143.png',
    resourceType: 'secure-storage-file',
    title: 'SSP Brochure for Clients',
    titleInHeader: true,
    shortDescription:
      'Print and share this brochure to introduce clients to the benefits of the SSP.',
    productCategory: ['SSP', 'Marketing', 'Getting Started'],
    src: 'Provider/SSP_Client_Brochure.pdf',
    a4src: 'Provider/SSP_Client_Brochure_A4.pdf',
    roles: ['provider_all_clients', 'provider', 'client'],
    uploadDate: '2024-02-01',
    isEnabledByRole: ({ userRoles }) =>
      userRoles && includesSome(userRoles, [...ROLES.PROVIDER_ROLES, ...ROLES.CLIENT_ROLES]),
    isEnabledByProductsStatus: () => true,
  },
  {
    id: '7bfb9d81-893e-47af-babf-c454b034d9f3',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/102.png',
    resourceType: 'secure-storage-file',
    title: 'ILS Brochure for Clients',
    titleInHeader: true,
    shortDescription:
      'Print and share this brochure to introduce clients to the benefits of the ILS.',
    productCategory: ['ILS', 'Marketing'],
    src: 'Provider/ILS_Client_Brochure.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2024-04-24',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: () => true,
  },
  {
    id: '3e03313c-db1e-4dd8-8a33-30698dbb548d',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/144.png',
    resourceType: 'videos',
    title: 'Considerations for SSP Delivery',
    titleInHeader: true,
    shortDescription:
      'Translate Polyvagal Theory into practice & share practical considerations for SSP delivery.',
    productCategory: ['SSP', 'Getting Started', 'Video'],
    src: '//player.vimeo.com/video/907625845',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2024-02-01',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasSspCertification }) => hasSspCertification,
  },
  {
    id: 'f543238b-fc8a-40cc-87cd-f466a880d625',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/145.png',
    resourceType: 'secure-storage-file',
    title: 'Athens Insomnia Scale (AIS)',
    titleInHeader: true,
    shortDescription: 'Learn more about the development, use, and scoring of the AIS.',
    productCategory: ['SSP', 'Assessment'],
    src: 'Provider/Assessments/MU+Cards+-+AIS.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2024-02-13',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasCompletedSspCertification, hasSspProducts }) =>
      hasCompletedSspCertification && hasSspProducts,
  },
  {
    id: 'df2e14f6-2b39-4cb5-a64e-80653fdc7251',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/149.png',
    resourceType: 'videos',
    title: 'Provider Coaching Session with Deb Dana',
    titleInHeader: true,
    shortDescription:
      'As you get started, learn about considerations for working with clients through a Polyvagal lens.',
    productCategory: ['SSP', 'Video', 'Getting Started'],
    src: '//player.vimeo.com/video/938715100',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2024-05-07',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasCompletedSspCertification }) => hasCompletedSspCertification,
  },
  {
    id: '126b296e-f59e-44dd-b39b-d1735dbb2fcf',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/126.png',
    resourceType: 'link',
    title: 'Regulation Through Listening: The Science and Applications',
    titleInHeader: true,
    shortDescription:
      'An exploration of science, clinical research, and real-world evidence on listening therapies.',
    productCategory: ['SSP', 'ILS'],
    src:
      'https://learn.unyte.com/hubfs/Whitepapers/Whitepaper-NervousSystemRegulationThroughListening-2024.pdf',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2024-07-17',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: () => true,
  },
  {
    id: '37a67ed8-8584-471a-a224-8e4fb95431be',
    pageTitle: 'Resource',
    imgSrc: '/images/resources/cards/147.png',
    resourceType: 'videos',
    title: 'The To Be Loved Book Discussion',
    titleInHeader: true,
    shortDescription:
      'An authentic and open conversation with Dr. Frank Anderson about trauma and healing.',
    productCategory: ['SSP', 'ILS', 'Video'],
    src: '//player.vimeo.com/video/986741555',
    roles: ['provider_all_clients', 'provider'],
    uploadDate: '2024-07-24',
    isEnabledByRole: ({ userRoles }) => userRoles && includesSome(userRoles, ROLES.PROVIDER_ROLES),
    isEnabledByProductsStatus: ({ hasCompletedSspCertification, hasCompletedFocusCertification }) =>
      hasCompletedSspCertification || hasCompletedFocusCertification,
  },
]

const activity_library = [
  {
    id: 'bf6c0531-de8e-4267-b197-40de57f9150a',
    imgSrc: '/images/resources/focus-resources/activities/Visual-Motor.png',
    title: 'ILS Activity Sets',
    shortDescription:
      'A guide of suggested activity sets to incorporate movement activities into ILS listening sessions.',
    productCategory: [],
    uploadDate: '2024-04-09',
    resourceType: 'secure-storage-file',
    src: 'ILS_Activity_Sets.pdf',
  },
  {
    id: 'ee2755ff-43f7-4195-8588-829fd39e0e02',
    imgSrc: '/images/resources/focus-resources/activities/Regulation.png',
    title: 'Even Breathing',
    shortDescription:
      'Pair breath with stillness & posture at the start of or throughout sessions.',
    productCategory: ['Regulation'],
    uploadDate: '2022-04-14',
  },
  {
    id: '8946bc88-dd22-4c53-8cbd-b2db45fa830f',
    imgSrc: '/images/resources/focus-resources/activities/Gross-Motor.png',
    title: 'Even Breathing on Balance Board',
    shortDescription: 'Add a balance challenge to the "Even Breathing" exercise.',
    productCategory: ['Regulation', 'Gross Motor'],
    uploadDate: '2022-04-14',
  },
  {
    id: '0176a350-5555-482a-b2b4-96e39838524b',
    imgSrc: '/images/resources/focus-resources/activities/Regulation.png',
    title: 'Helicopter Warm-up',
    shortDescription:
      'Provide vestibular and cross-body input for arousal and building motor skills.',
    productCategory: ['Gross Motor'],
    uploadDate: '2022-04-14',
  },
  {
    id: '27a5a34d-42a4-482c-9106-654759526cfe',
    imgSrc: '/images/resources/focus-resources/activities/Gross-Motor.png',
    title: 'Standing Cross-crawl',
    shortDescription:
      'Integrate left - right brain hemispheres through alternating kicks while standing.',
    productCategory: ['Gross Motor'],
    uploadDate: '2022-04-14',
  },
  {
    id: '389af4ce-1b33-4727-9858-62dffb09364f',
    imgSrc: '/images/resources/focus-resources/activities/Fine-Motor.png',
    title: 'Crazy Count',
    shortDescription:
      'Use both hands paired with counting to build bilateral coordination and attention.',
    productCategory: ['Fine Motor'],
    uploadDate: '2022-04-14',
  },
  {
    id: 'fb2af078-6f2e-4225-acb5-0e4d3d7b94d8',
    imgSrc: '/images/resources/focus-resources/activities/Daily-Living.png',
    title: 'Drop & Catch',
    shortDescription:
      'Use this bean-bag game to integrate left-right brain hemispheres and build motor skills by alternating drop and catch hands.',
    productCategory: ['Gross Motor'],
    uploadDate: '2022-04-14',
  },
  {
    id: '3c03be10-9528-4a71-ac1c-28f5387058ae',
    imgSrc: '/images/resources/focus-resources/activities/Visual-Motor.png',
    title: 'Toss & Catch w/ Partner',
    shortDescription:
      'Play this partner bean-bag game to integrate left-right brain hemispheres and build visual-motor skills.',
    productCategory: ['Gross Motor', 'Visual Motor'],
    uploadDate: '2022-04-14',
  },
  {
    id: 'e6219bb1-69bd-4ea8-a170-ec4e885cd700',
    imgSrc: '/images/resources/focus-resources/activities/Cognitive.png',
    title: 'Wall/Floor Bounce',
    shortDescription:
      'Integrate left-right brain hemispheres to build motor and visual motor skills using a ball and a wall.',
    productCategory: ['Gross Motor', 'Visual Motor'],
    uploadDate: '2022-04-14',
  },
  {
    id: '8198c8cc-7131-4a73-8277-c878bd91a1fd',
    imgSrc: '/images/resources/focus-resources/activities/Visual-Motor.png',
    title: 'Cross Kick Warm-up',
    shortDescription:
      'Invigorate arousal & prepare both sides of the body with alternating easy kicks.',
    productCategory: ['Gross Motor', 'Visual Motor'],
    uploadDate: '2022-04-14',
  },
  {
    id: '721309ec-dd17-4a10-b369-6b32acd997cc',
    imgSrc: '/images/resources/focus-resources/activities/Early-Development.png',
    title: 'Balance Board Standing',
    shortDescription: 'Challenge or improve balance and upright postural stability.',
    productCategory: ['Gross Motor', 'Early Development'],
    uploadDate: '2022-04-14',
  },
  {
    id: '2b18fa1a-34b4-497b-8a5b-6cb863c0d120',
    imgSrc: '/images/resources/focus-resources/activities/Gross-Motor.png',
    title: 'Scissor Legs',
    shortDescription:
      'This dynamic standing activity mimics scissor-like lower extremity movements.',
    productCategory: ['Gross Motor', 'Early Development'],
    uploadDate: '2022-04-14',
  },
  {
    id: 'fbf31ce0-a5fe-47c5-95aa-57525fe00edf',
    imgSrc: '/images/resources/focus-resources/activities/Regulation.png',
    title: 'Sunrise Stretch',
    shortDescription: 'Reach high and bend low to improve arousal and bring attention to the body.',
    productCategory: ['Regulation', 'Gross Motor', 'Early Development'],
    uploadDate: '2022-04-14',
  },
  {
    id: 'ecc08369-3619-49f1-a07d-a5c4717d31a6',
    imgSrc: '/images/resources/focus-resources/activities/Early-Development.png',
    title: 'Arm Leg Lift',
    shortDescription:
      'Build strength and co-activation for motor skills with this lying down cross-body exercise.',
    productCategory: ['Gross Motor', 'Early Development'],
    uploadDate: '2022-04-14',
  },
  {
    id: '2094dc0b-05c5-4c85-8fc5-b562fab11647',
    imgSrc: '/images/resources/focus-resources/activities/Regulation.png',
    title: 'Jumping Jacks',
    shortDescription:
      'Get both sides of the body involved in building co-ordination, arousal regulation, and endurance.',
    productCategory: ['Gross Motor', 'Early Development'],
    uploadDate: '2022-04-14',
  },
  {
    id: 'cfd43ded-56dc-4f33-a478-6d1633a694cb',
    imgSrc: '/images/resources/focus-resources/activities/Visual-Motor.png',
    title: 'Floor Bounce & Catch',
    shortDescription:
      'Get eyes and hands working together when bouncing and catching a ball at midline.',
    productCategory: ['Gross Motor', 'Visual Motor'],
    uploadDate: '2022-04-14',
  },
  {
    id: '5308ef24-334e-4725-bb58-4f6e16c50ac5',
    imgSrc: '/images/resources/focus-resources/activities/Early-Development.png',
    title: 'Parallel Skiing',
    shortDescription:
      'Jump side-to-side as though wearing pretend skis to build co-ordination and endurance.',
    productCategory: ['Gross Motor', 'Early Development'],
    uploadDate: '2022-04-14',
  },
  {
    id: '2b8e7c2e-e958-45a5-b148-e039d9135275',
    imgSrc: '/images/resources/focus-resources/activities/Visual-Motor.png',
    title: 'Under & Twirl',
    shortDescription:
      'Increase amount of vestibular input by bouncing a medium ball on a wall behind you.',
    productCategory: ['Gross Motor', 'Visual Motor'],
    uploadDate: '2022-04-14',
  },
  {
    id: 'c2f3e761-156d-4c9a-98cc-8c30710c5ae8',
    imgSrc: '/images/resources/focus-resources/activities/Regulation.png',
    title: 'Wall Push-ups',
    shortDescription:
      'This standing push-up against a wall builds body awareness and connection to self.',
    productCategory: ['Gross Motor'],
    uploadDate: '2022-04-14',
  },
  {
    id: '6a15643e-4a3d-4142-af2b-f45bcbef9c1c',
    imgSrc: '/images/resources/focus-resources/activities/Daily-Living.png',
    title: 'Lazy 8',
    shortDescription: 'Trace an imaginary horizontal figure 8 for visual-motor skills.',
    productCategory: ['Gross Motor', 'Visual Motor', 'Early Development'],
    uploadDate: '2022-04-14',
  },
  {
    id: '33f816fe-1f66-4b1e-9166-1c805c17a47ac',
    imgSrc: '/images/resources/focus-resources/activities/Gross-Motor.png',
    title: 'Cross-crunch',
    shortDescription: 'Add cross-body movement to crunches for more impact on motor skills.',
    productCategory: ['Gross Motor'],
    uploadDate: '2022-04-14',
  },
  {
    id: '4f147250-cbae-4a6c-8d5a-351936e45cf6',
    imgSrc: '/images/resources/focus-resources/activities/Early-Development.png',
    title: 'Thumb Game',
    shortDescription:
      'This bilateral hand game offers a fun tricky way to build motor skills while sitting.',
    productCategory: ['Gross Motor', 'Early Development'],
    uploadDate: '2022-04-14',
  },
  {
    id: '8109e894-3798-4bea-a6ec-e52e89ed2a88',
    imgSrc: '/images/resources/focus-resources/activities/Gross-Motor.png',
    title: 'Dog Walk',
    shortDescription:
      'Emphasize controlled movement by balancing something on your back while crawling.',
    productCategory: ['Gross Motor', 'Early Development'],
    uploadDate: '2022-04-14',
  },
  {
    id: 'fc479c40-3954-4031-bcc6-fd026adec972',
    imgSrc: '/images/resources/focus-resources/activities/Fine-Motor.png',
    title: 'Finger Lift',
    shortDescription: 'Motor planning and finger isolation can happen in the hands while seated.',
    productCategory: ['Fine Motor', 'Early Development'],
    uploadDate: '2022-04-14',
  },
  {
    id: '3165fcbb-7683-43db-b275-08ad77d982e5',
    imgSrc: '/images/resources/focus-resources/activities/Early-Development.png',
    title: 'Ball Roll',
    shortDescription:
      'Floor-sitting upright facing a partner to roll and receive a ball builds basic motor skills and relationship.',
    productCategory: ['Gross Motor', 'Early Development'],
    uploadDate: '2022-04-14',
  },
  {
    id: '6c890d6e-5f1b-42b2-a4b7-525ab08c9b6b',
    imgSrc: '/images/resources/focus-resources/activities/Gross-Motor.png',
    title: 'Balance Board Sitting',
    shortDescription:
      'Sitting on a balance board with legs crossed presents a unique balance challenge.',
    productCategory: ['Gross Motor', 'Early Development'],
    uploadDate: '2022-04-14',
  },
  {
    id: 'a828f3f9-3f8d-4351-b8f1-d953295828c4',
    imgSrc: '/images/resources/focus-resources/activities/Visual-Motor.png',
    title: 'Smart Dog',
    shortDescription: 'Combine crawling and memory to build motor skills and attention.',
    productCategory: ['Gross Motor', 'Visual Motor'],
    uploadDate: '2022-04-14',
  },
  {
    id: '8ea21478-8b69-490b-be6e-4ef09466e0874',
    imgSrc: '/images/resources/focus-resources/activities/Gross-Motor.png',
    title: 'Drop, Catch & Under Leg',
    shortDescription:
      'Motor sequencing and balance are needed to drop & catch a bean bag then transfer it under the leg.',
    productCategory: ['Gross Motor', 'Visual Motor'],
    uploadDate: '2022-04-14',
  },
  {
    id: 'b41115d8-4589-48d4-899c-3020f5f296cc',
    imgSrc: '/images/resources/focus-resources/activities/Cognitive.png',
    title: 'Drop, Catch & Behind Back',
    shortDescription:
      'Add complexity to motor sequencing by dropping and catching a bean bag then transferring it behind the back.',
    productCategory: ['Gross Motor'],
    uploadDate: '2022-04-14',
  },
  {
    id: 'bb221b59-59d8-4e3e-aff4-f88add17b177',
    imgSrc: '/images/resources/focus-resources/activities/Quiet-Time.png',
    title: 'Funny Walk',
    shortDescription:
      'Walk on all fours and make it funny by moving left side hand & foot then switch.',
    productCategory: ['Gross Motor'],
    uploadDate: '2022-04-14',
  },
  {
    id: '31a034e6-1070-402a-9b5e-1767d06c8656',
    imgSrc: '/images/resources/focus-resources/activities/Early-Development.png',
    title: 'Bump & Roll',
    shortDescription: 'Bump a medium sized ball with your head while crawling.',
    productCategory: ['Gross Motor'],
    uploadDate: '2022-04-14',
  },
  {
    id: 'c0ab3808-fdef-4414-aa2f-566a8f5a5d9e',
    imgSrc: '/images/resources/focus-resources/activities/Gross-Motor.png',
    title: 'Dodge Ball',
    shortDescription: 'Avoid being touched by the hanging ball without moving your feet.',
    productCategory: ['Gross Motor', 'Visual Motor'],
    uploadDate: '2022-04-14',
  },
  {
    id: '0d338bc6-d61d-4dbd-a7bc-ba798bd9c310',
    imgSrc: '/images/resources/focus-resources/activities/Visual-Motor.png',
    title: 'Straight Line Taps',
    shortDescription:
      'Alternating hands tap with just enough force to swing the ball back and forth.',
    productCategory: ['Gross Motor', 'Visual Motor'],
    uploadDate: '2022-04-14',
  },
]

const FOCUS_ACTIVITY_LIBRARY = activity_library.map((activity) => {
  return {
    pageTitle: 'Resource - Activity Library',
    resourceType: 'focusActivity',
    titleInHeader: false,
    src: null,
    roles: ['provider_all_clients', 'provider', 'client'],
    isEnabledByRole: () => true,
    isEnabledByProductsStatus: ({ hasFocusCertification, clientHasFocus }) =>
      hasFocusCertification || clientHasFocus,
    tag: 'activity-library',
    ...activity,
    productCategory: [...activity.productCategory, 'ILS Activity'],
  }
})

const regulating_activities = [
  {
    id: '2cb21a84-b76d-483a-b6da-7f24631a5c1b',
    title: '4-part Box Breath',
    shortDescription: 'Activate the vagus nerve by breathing with rhythm, pause and intention.',
    imgSrc: '/images/resources/regulating-activities/regulation.png',
    productCategory: ['Breathing'],
  },
  {
    id: '294f4fef-7dac-45cf-86dd-c404adc0a2eb',
    title: 'Diaphragmatic Breathing',
    shortDescription: 'Ground yourself with deep breaths, focusing on the movement of your body.',
    imgSrc: '/images/resources/regulating-activities/regulation.png',
    productCategory: ['Breathing'],
  },
  {
    id: '56afd17e-c6f7-4267-b7c4-b4f2037617e6',
    title: 'Extended Exhale',
    shortDescription: 'Slow down your breathing through a longer exhalation.',
    imgSrc: '/images/resources/regulating-activities/regulation.png',
    productCategory: ['Breathing'],
  },
  {
    id: '3abd32cd-292f-446d-9fc6-f2a192524514',
    title: 'Yawning',
    shortDescription:
      'Stimulate the part of your brain that supports self-reflection and memory retrieval.',
    imgSrc: '/images/resources/regulating-activities/regulation.png',
    productCategory: ['Breathing'],
  },
  {
    id: 'db22960e-f502-48eb-bc2e-376bd5803115',
    title: 'Sensory Orientation',
    shortDescription:
      'Bring attention to your body and your environment, and away from disruptive thoughts.',
    imgSrc: '/images/resources/regulating-activities/mindfulness.png',
    productCategory: ['Mindfulness'],
  },
  {
    id: '30fd2dea-0d8b-4a5f-9cd9-28797b3d355c',
    title: 'Visualization',
    shortDescription:
      'Close your eyes and picture yourself somewhere safe to reduce symptoms of anxiety.',
    imgSrc: '/images/resources/regulating-activities/mindfulness.png',
    productCategory: ['Mindfulness'],
  },
  {
    id: 'c44dc453-898f-4285-9af8-2072b02e3853',
    title: 'Ear Massage',
    shortDescription: 'Stimulate the vagus nerve and feel more relaxed with just your fingers.',
    imgSrc: '/images/resources/regulating-activities/mindfulness.png',
    productCategory: ['Mindfulness'],
  },
  {
    id: '7909fd14-bd8b-4e9c-9fd2-b799e215378b',
    title: 'Joint Circles',
    shortDescription:
      'Relieve key areas of your body from tension you may be feeling with simple exercises.',
    imgSrc: '/images/resources/regulating-activities/movement.png',
    productCategory: ['Movement'],
  },
  {
    id: '32770a3c-20db-4b8b-ad30-3b29e77380c4',
    title: 'Releasing the Neck',
    shortDescription: 'Relieve the muscles in the neck area to decompress the vagus nerve.',
    imgSrc: '/images/resources/regulating-activities/movement.png',
    productCategory: ['Movement'],
  },
  {
    id: '7a14eccb-193c-4327-9b5f-948e2f2fe84f',
    title: 'Self-touch',
    shortDescription: 'Practice self-love with a hug, which has been shown to help lower stress.',
    imgSrc: '/images/resources/regulating-activities/movement.png',
    productCategory: ['Movement'],
  },
  {
    id: 'a5c39579-5625-4b71-8c11-c90613cb1719',
    title: 'Yoga',
    shortDescription:
      'Physical activity has been shown to have a positive effect on depression and anxiety.',
    imgSrc: '/images/resources/regulating-activities/movement.png',
    productCategory: ['Movement'],
  },
  {
    id: '754d5642-bbce-4964-a478-a5de9a62b75d',
    title: 'Singing and Chanting',
    shortDescription:
      'Sing a song or a simple chant while taking notice of changes in your mood or focus.',
    imgSrc: '/images/resources/regulating-activities/vocalization.png',
    productCategory: ['Vocalization'],
  },
]

const REGULATING_ACTIVITY_LIBRARY = regulating_activities.map((activity) => {
  return {
    ...activity,
    productCategory: [...activity.productCategory, 'Regulating Activity'],
    pageTitle: 'Resource - Regulating Activities',
    resourceType: 'regulatingActivity',
    titleInHeader: false,
    src: null,
    roles: ['provider_all_clients', 'provider', 'client'],
    uploadDate: '2023-11-23',
    isEnabledByRole: () => true,
    isEnabledByProductsStatus: () => true,
    tag: 'activity-library',
  }
})

export default [
  ...GENERAL_RESOURCES,
  ...PROVIDER_TOOLKIT_RESOURCES,
  ...FOCUS_ACTIVITY_LIBRARY,
  ...REGULATING_ACTIVITY_LIBRARY,
]
