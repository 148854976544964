import React, { useEffect } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import AudioPlayer from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.css'
import './audioPlayerStyles.css'

import { Button, Card, CardContent, CardMedia, Collapse, Grid, Link, Typography } from 'components'
import { themeDefinition } from '../../utils/theme/Theme'

const palette = themeDefinition.palette

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
  },
  text: {
    whiteSpace: 'pre-wrap',
    fontSize: '14px',
    color: palette.gray.medium,
    minHeight: '50px',
  },
  programDescription: {
    minHeight: '200px',
  },
  pathwayDescription: {
    height: '160px',
  },
  expanded: {
    color: '#000',
    transform: ({ expanded }) => (expanded ? 'rotate(180deg)' : 'rotate(0)'),
    height: '100%',
  },
  emptyExpansion: {
    height: '38px',
  },
  cardHeight: {
    height: '100%',
    width: '100%',
  },
  googleLogo: {
    height: 36,
    width: 107,
  },
  appleLogo: {
    height: 36,
    width: 119,
  },
  audioplayer: {
    height: '100px',
  },
  audioplayerFooter: {
    fontSize: '12px',
    color: palette.gray.medium,
    textAlign: 'center',
  },
  linkWrapper: {
    textAlign: 'right',
    paddingTop: '10px',
  },
  link: {
    textDecoration: 'none',
  },
  linkText: {
    color: palette.pink.dark,
  },
  labelText: {
    whiteSpace: 'pre-wrap',
    fontSize: '12px',
    color: palette.gray.medium,
    textAlign: 'center',
  },
  divider: {
    height: '1px',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    border: 'none',
    margin: '20px',
    width: '37%',
  },
})

export default function ProductCard({
  cardType,
  closeText,
  description,
  expandText,
  id,
  link,
  linkTo,
  metadata,
  name,
  expandedCard,
  setExpandedCard,
}) {
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState(false)

  const handleExpandClick = (cardName) => {
    if (!expanded) setExpandedCard(cardName)
    setExpanded(!expanded)
  }

  const borderSX = {
    borderTop: 10,
    borderBottom: 10,
    borderColor: metadata.borderColor,
  }

  useEffect(() => {
    if (expandedCard !== name) setExpanded(false)
  }, [expandedCard])

  return (
    <Grid item xs={12} md={6} lg={4} className={`${classes.card} p-2`} key={`product-${id}`}>
      <Card justify="center" className={classes.cardHeight}>
        <CardMedia
          sx={metadata?.borderColor ? borderSX : null}
          height="200"
          component="img"
          src={metadata.pictureUrl}
        />
        <CardContent style={{ paddingBottom: '0px' }}>
          <div className={classes[`${cardType}Description`]}>
            <Grid container>
              <Grid item xs={6}>
                <Typography className={`${classes.title} mt-2 mb-3`}>{name}</Typography>
              </Grid>
              {link && linkTo && (
                <Grid className={classes.linkWrapper} item xs={6}>
                  <Link to={linkTo} target="_blank" className={classes.link}>
                    <Typography className={classes.linkText}>{link}</Typography>
                  </Link>
                </Grid>
              )}
            </Grid>
            <Typography className={classes.text} paragraph>
              {description}
            </Typography>
            {metadata.expandedContent ? (
              <>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  {cardType === 'playlist' && (
                    <>
                      <div className={classes.audioplayer}>
                        <AudioPlayer
                          autoPlay
                          layout="horizontal-reverse"
                          showJumpControls={false}
                          customAdditionalControls={[]}
                          customVolumeControls={[]}
                          header={`SSP ${name}`}
                          src={metadata.expandedContent}
                        />
                      </div>
                      <Typography className={classes.audioplayerFooter}>
                        Access the full playlist through the Unyte Health app
                      </Typography>
                    </>
                  )}
                  {cardType === 'product' && (
                    <Typography className={classes.text} style={{ color: '#616161' }} paragraph>
                      {metadata.expandedContent}
                    </Typography>
                  )}
                </Collapse>
                <Grid container direction="row" justifyContent="space-around" alignItems="center">
                  <Grid item xs className={classes.divider} />
                  <Grid item xs="auto">
                    <Button onClick={() => handleExpandClick(name)} aria-label="show more">
                      <Typography variant="button">
                        {expanded ? closeText || 'Less' : expandText || 'More'}
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid item xs className={classes.divider} />
                </Grid>
              </>
            ) : (
              <div className={classes.emptyExpansion} />
            )}
          </div>
        </CardContent>
      </Card>
    </Grid>
  )
}
