import React, { useState } from 'react'
import { useQuery, gql } from 'utils/apollo'
import { get } from 'lodash'
import { Grid, Stack, Typography } from '@mui/material'

const GET_DOWNLOAD_CONTENT_URL = gql`
  query GetDownloadContentUrl($resourceFilePath: String) {
    getDownloadContentUrl(resourceFilePath: $resourceFilePath)
  }
`

const GET_DOWNLOAD_CONTENT_URL_A4 = gql`
  query GetDownloadContentUrlA4($resourceFilePath: String) {
    getDownloadContentUrl(resourceFilePath: $resourceFilePath)
  }
`

export function useSecureFileLink(resourceFileUrl) {
  const [secureURL, setSecureURL] = useState('')
  useQuery(GET_DOWNLOAD_CONTENT_URL, {
    fetchPolicy: 'network-only',
    variables: {
      resourceFilePath: resourceFileUrl,
    },
    onCompleted: (data) => {
      setSecureURL(get(data, 'getDownloadContentUrl.resourceFileUrl'))
    },
  })
  return secureURL
}

export default function SecureStorageFile({ resource, height }) {
  const [resourceFileUrl, setResourceFileUrl] = useState()
  const [resourceFileUrlA4, setResourceFileUrlA4] = useState('')

  useQuery(GET_DOWNLOAD_CONTENT_URL, {
    fetchPolicy: 'network-only',
    variables: {
      resourceFilePath: resource.src,
    },
    onCompleted: (data) => {
      // a cloudfront link is always returned here, this will never be empty.
      // but we still need error handling for unable to retrieve the file.
      // TODO: https://unytehealth.atlassian.net/browse/UW-6513
      setResourceFileUrl(get(data, 'getDownloadContentUrl.resourceFileUrl'))
    },
  })

  useQuery(GET_DOWNLOAD_CONTENT_URL_A4, {
    skip: !resource.a4src,
    fetchPolicy: 'network-only',
    variables: {
      resourceFilePath: resource.a4src,
    },
    onCompleted: (data) => {
      // a cloudfront link is always returned here, this will never be empty.
      // but we still need error handling for unable to retrieve the file.
      // TODO: https://unytehealth.atlassian.net/browse/UW-6513
      setResourceFileUrlA4(get(data, 'getDownloadContentUrl.resourceFileUrl'))
    },
  })

  return (
    <Stack py={1} spacing={2} direction="column">
      <Grid container alignItems="center" className="pt-3 pb-2 pl-5">
        <Grid item xs={2} />
        <Grid item xs align="center">
          <Typography variant="h5" className="text-gray">
            {!resource.downloadFile && (
              <Typography variant="subtitle1" className="text-gray" align="center">
                If you are having trouble viewing this resource, please try accessing the resource{' '}
                <a
                  className="text-link underline cursor-pointer font-medium"
                  href={resourceFileUrl}
                  target="_blank"
                  rel="noreferrer noopener"
                  download={resource.downloadFile}
                >
                  here
                </a>
                .
              </Typography>
            )}
            {!!resource.downloadFile && (
              <Typography variant="subtitle1" className="text-gray" align="center">
                <a
                  className="text-link underline cursor-pointer font-medium"
                  href={resourceFileUrl}
                  target="_blank"
                  rel="noreferrer noopener"
                  download={resource.downloadFile}
                >
                  Download images here
                </a>
              </Typography>
            )}

            {resourceFileUrlA4 && (
              <Typography variant="subtitle1" className="text-gray" align="center" pb={1}>
                To access this resource in A4 format, click{' '}
                <a
                  className="text-link underline cursor-pointer font-medium"
                  href={resourceFileUrlA4}
                  target="_blank"
                  rel="noreferrer noopener"
                  download={resourceFileUrlA4}
                >
                  here
                </a>
                .
              </Typography>
            )}
          </Typography>
        </Grid>
        <Grid item xs={2} />
      </Grid>
      <iframe
        title="account video"
        src={resourceFileUrl}
        width="100%"
        height={Math.round(height)}
        frameBorder="0"
        allowFullScreen
      />
    </Stack>
  )
}
