import React from 'react'
import { useTheme } from '@mui/material/styles'
import { useSecureFileLink } from '../../components/resources/SecureStorageFile'
import files from '../../utils/constants/files'
import ProductCard from './ProductCard'

export default function PlaylistCards({ expandedCard, setExpandedCard }) {
  const theme = useTheme()

  const playlistCards = [
    {
      name: 'Original',
      id: 'original',
      description:
        'A collection of contemporary songs from the 1970s to date' +
        ' that you may already be familiar with.',
      metadata: {
        borderColor: theme.palette.mint.dark,
        pictureUrl: '/images/playlists/Original.png',
        expandedContent: useSecureFileLink(files.sspAudioSamples.original),
      },
      expandText: 'Listen to sample',
      closeText: 'Close',
      link: 'View song playlist',
      linkTo:
        'https://my.unyte.com/resources/SSP-Original-Playlist/ba727841-ecd2-436f-8c11-bbcc2e6eb858?q=original',
    },
    {
      name: 'Wonder',
      id: 'wonder',
      description:
        'Playful, familiar songs that appeal to younger ages, but can be enjoyed by all.',
      metadata: {
        borderColor: theme.palette.mint.dark,
        pictureUrl: '/images/playlists/Wonder.png',
        expandedContent: useSecureFileLink(files.sspAudioSamples.wonder),
      },
      expandText: 'Listen to sample',
      closeText: 'Close',
      link: 'View song playlist',
      linkTo:
        'https://my.unyte.com/resources/SSP-Wonder-Playlist/5f5a499d-3627-4f66-98ff-24cc19398967?q=wonder',
    },
    {
      name: 'Groove',
      id: 'groove',
      description:
        'An eclectic mix of relaxing instrumentals, pop/rock songs, ' +
        'and percussive beats by acclaimed artist and educator Jim Donovan, M.Ed.',
      metadata: {
        borderColor: theme.palette.mint.dark,
        pictureUrl: '/images/playlists/Groove.png',
        expandedContent: useSecureFileLink(files.sspAudioSamples.groove),
      },
      expandText: 'Listen to sample',
      closeText: 'Close',
      link: 'View song playlist',
      linkTo:
        'https://my.unyte.com/resources/SSP-Groove-Playlist/6eacaa3c-6422-4d14-84ff-0372e925a908?q=groove',
    },
    {
      name: 'Groove (Instrumental)',
      id: 'groove-instrumental',
      description:
        'An eclectic mix of relaxing instrumentals, pop/rock songs, and percussive beats with no lyrics, ' +
        'by acclaimed artist and educator Jim Donovan, M.Ed.',
      metadata: {
        borderColor: theme.palette.mint.dark,
        pictureUrl: '/images/playlists/GrooveInstrumental.png',
        expandedContent: useSecureFileLink(files.sspAudioSamples.grooveInstrumental),
      },
      expandText: 'Listen to sample',
      closeText: 'Close',
      link: 'View song playlist',
      linkTo:
        'https://my.unyte.com/resources/SSP-Groove-Playlist/6eacaa3c-6422-4d14-84ff-0372e925a908?q=groove',
    },
    {
      name: 'Freely',
      id: 'freely',
      description:
        'Original composition of instrumental, ' +
        'ambient fusion music by artist and neuroscientist Dr. AZA Stephen Allsop, ' +
        'MD, Ph.D.',
      metadata: {
        borderColor: theme.palette.mint.dark,
        pictureUrl: '/images/playlists/Freely.png',
        expandedContent: useSecureFileLink(files.sspAudioSamples.freely),
      },
      expandText: 'Listen to sample',
      closeText: 'Close',
      link: 'View song playlist',
      linkTo:
        'https://my.unyte.com/resources/SSP-Freely-Playlist/db26aacd-1b59-4ea6-9474-a41373059f98?q=freely',
    },
    {
      name: 'Classical Flow',
      id: 'classical-flow',
      description:
        'Curated classical music, featuring symphonies, ' +
        'concertos and serenades, waltzes, and sonatas, using primarily Mozart, ' +
        'for its inherent flow and ease.',
      metadata: {
        borderColor: theme.palette.mint.dark,
        pictureUrl: '/images/playlists/ClassicalFlow.png',
        expandedContent: useSecureFileLink(files.sspAudioSamples.flow),
      },
      expandText: 'Listen to sample',
      closeText: 'Close',
      link: 'View song playlist',
      linkTo:
        'https://my.unyte.com/resources/SSP-Classical-Flow-Playlist/926ef839-7b33-45ce-b7e0-d1c64dd05dd3?q=classical',
    },
    {
      name: 'Classical Calm',
      id: 'classical-calm',
      description:
        'Curated classical music designed to be calming and grounding, ' +
        'with a soothing tempo and without abrupt changes.',
      metadata: {
        borderColor: theme.palette.mint.dark,
        pictureUrl: '/images/playlists/ClassicalCalm.png',
        expandedContent: useSecureFileLink(files.sspAudioSamples.calm),
      },
      expandText: 'Listen to sample',
      closeText: 'Close',
      link: 'View song playlist',
      linkTo:
        'https://my.unyte.com/resources/SSP-Classical-Calm-Playlist/46173427-86a7-44cf-97b2-0958b03b9123?q=classical',
    },
  ]

  return (
    <>
      {playlistCards.map(
        ({ closeText, description, expandText, id, link, linkTo, name, metadata }) => (
          <ProductCard
            name={name}
            id={id}
            key={id}
            description={description}
            metadata={metadata}
            expandText={expandText}
            closeText={closeText}
            cardType={'playlist'}
            link={link}
            linkTo={linkTo}
            expandedCard={expandedCard}
            setExpandedCard={setExpandedCard}
          />
        )
      )}
    </>
  )
}
